import { useEffect, useState } from 'react';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { Panel, Panels } from '../../components/Tabs/Panels';
import { MonthlyPayroll } from './MontlyPayroll';
import { PaidHolidays } from './PaidHolidays';
import { PayrollSettings } from './PayrollSettings';

export const PayrollPage = () => {
  const [activePanel, setActivePanel] = useState<typeof Panel>();
  const [firstPanelRef, setFirstPanelRef] = useState();

  useEffect(() => setActivePanel(firstPanelRef), [firstPanelRef]);

  return (
    <div>
      <EmptyPageLayout title={'Payroll'}>
        <Panels activePanel={activePanel} onActivePanelChange={setActivePanel}>
          <Panel ref={setFirstPanelRef}>
            <MonthlyPayroll />
          </Panel>
          <Panel>
            <PayrollSettings />
          </Panel>
          <Panel>
            <PaidHolidays />
          </Panel>
        </Panels>
      </EmptyPageLayout>
    </div>
  );
};
