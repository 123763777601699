import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Search } from '../Search/Search';
import { Spinner } from '../Spinner/Spinner';
import { EmptyPageLayout } from './EmptyPageLayout';

export type AddNew = () => void;

interface PageLayoutProps {
  title: string;
  subtitle?: string;
  dataCount?: number;
  children?: React.ReactNode;
  loading?: boolean;
  onAdd?: AddNew;
  onFilterUpdated?: (filter: string) => void;
}

export const PageLayout = ({
  title,
  subtitle,
  children,
  dataCount,
  loading,
  onAdd,
  onFilterUpdated
}: PageLayoutProps) => {
  return (
    <EmptyPageLayout title={title}>
      {loading ? (
        <div className='flex justify-center mt-10'>
          <Spinner />
        </div>
      ) : (
        <div>
          <div className='sm:flex sm:items-center mb-8'>
            <div className='sm:flex-auto'>
              <div className='flex'>
                <h1 className='text-xl font-semibold text-gray-900'>{dataCount?.toString()}</h1>
                <h1 className='lowercase text-xl text-gray-900 ml-2'>{subtitle}</h1>
              </div>
            </div>
            {onFilterUpdated && <Search onFilterUpdated={onFilterUpdated} placeholder={subtitle} />}

            <div className='mt-4 sm:mt-0 sm:ml-8 sm:flex-none'>
              {onAdd && (
                <button
                  type='button'
                  className='inline-flex items-center justify-center rounded-md border border-transparent bg-royal-dark-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-royal-blue/90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                  onClick={onAdd}
                >
                  <PlusIcon className='h-5 w-5' aria-hidden='true' />
                  Add new {subtitle}
                </button>
              )}
            </div>
          </div>

          {children}
        </div>
      )}
    </EmptyPageLayout>
  );
};
