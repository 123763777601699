export const createMonthlyBillingSummarySheet = (data: any) => {
  const result: any = [];

  const billing = data !== undefined ? data : [];

  result.push([
    'Employee',
    'Customer',
    'Business ID',
    'Project',
    'Project PO',
    'Employee PO',
    'EUR/h',
    'Hours',
    'WD',
    'Billing',
    'Employee Commission',
    'Employee Commission EUR 2%',
    'Sales Person',
    'Sales Commission EUR 1.25%'
  ]);
  if (billing) {
    billing.forEach((e: any) => {
      var row = [
        `${e.employee.last_name} ${e.employee.first_name}`,
        e.project.customer.name,
        e.project.customer.business_id,
        e.project.name,
        e.project.po,
        e.assignment.po,
        e.assignment.external_price,
        e.hours,
        e.working_days,
        e.billing,
        '',
        e.employee_comission,
        e.project.sales === null
          ? ''
          : `${e.project.sales?.last_name} ${e.project.sales?.first_name}`,
        e.sales_comission
      ];
      result.push(row);
    });
  }
  result.push([
    'Totals',
    '',
    '',
    '',
    '',
    '',
    `=AVERAGE(E2:E${billing.length + 1})`,
    `=SUM(F2:F${billing.length + 1})`,
    `=SUM(G2:G${billing.length + 1})`,
    `=SUM(H2:H${billing.length + 1})`,
    '',
    `=SUM(J2:J${billing.length + 1})`,
    '',
    `=SUM(L2:L${billing.length + 1})`
  ]);

  return result;
};
