import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { GoogleSignIn } from '../logos/GoogleSignIn';
import { HellonSymbol } from '../logos/HellonSymbol';
import { Spark } from '../logos/Spark';
import { useAuth } from '../providers/Auth';
import DefinedRoutes from '../routes/DefinedRoutes';

export const Login = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (auth?.authenticated && location.state != null) {
    return <Navigate to={location.state.from?.pathname} />;
  }

  const authInProgress = auth?.inProgress || location.search.includes('code');

  if (auth?.authenticated) {
    if (auth?.user?.isAdmin()) {
      const origin = location.state?.from?.pathname || DefinedRoutes.DASHBOARD;
      navigate(origin, { replace: true });
    } else {
      return <Navigate to={DefinedRoutes.NO_SUPER_POWERS} replace />;
    }
  }

  const buttonLogo = () => {
    if (authInProgress) {
      return <ArrowPathIcon className='h-6 w-6 text-gray-400 animate-spin' aria-hidden='true' />;
    } else {
      return <GoogleSignIn />;
    }
  };

  return (
    <div className='flex w-screen min-w-full max-w-full h-screen min-h-full flex-col justify-center bg-black overflow-clip'>
      <div className='flex h-28 justify-center align-middle text-center'>
        <HellonSymbol className='fill-white' />
      </div>

      <div className='w-[calc(100vw-160px)] mt-20 mb-10 ml-auto mr-auto'>
        <Spark />
      </div>

      <h2 className='text-center text-3xl font-bold tracking-tight'>Welcome to Hellon Hive</h2>

      <div className='flex justify-center'>
        <button
          type='submit'
          className='inline-flex mt-14 bg-white w-72 h-12 px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-black focus:outline-none focus:ring-0 focus:ring-offset-1'
          onClick={() => {
            auth?.signIn();
          }}
        >
          {buttonLogo()}
          <span className='flex-1 text-center -ml-6'>Sign in with Google</span>
        </button>
      </div>
    </div>
  );
};
