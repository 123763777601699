import { ExclamationCircleIcon, WalletIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { Spinner } from '../../components/Spinner/Spinner';
import { Tabs } from '../../components/Tabs/Tabs';
import { Employee, useEmployeesWithAssignmentsQuery } from '../../generated/graphql';
import { employeeBasicInfoOk, employeeFullname } from '../../models/EmployeeTypes';
import { EmployeeDetails } from './EmployeeDetails';
import { EmployeeOverview } from './EmployeeOverview';
import { EmployeeProjects } from './EmployeeProjects';

export const EmployeePage = () => {
  let { id } = useParams();

  const { loading, data, refetch } = useEmployeesWithAssignmentsQuery({
    variables: {
      where: {
        id: {
          _eq: id
        }
      }
    }
  });

  const constructTitle = () => {
    if (loading) {
      return 'Loading ...';
    } else if (employee) {
      return employeeFullname(employee);
    } else {
      return 'Not found !!';
    }
  };

  const employees = (data?.employee || []) as Employee[];
  const employee = employees[0] || {};

  const currentBalance = () => {
    return (
      <div className='flex align-middle items-center'>
        <WalletIcon className='h-6 w-6 text-gold ' />
        <span className='text-2xl lg:text-3xl font-light mr-4 ml-4'>
          {employee.balance?.rolling_balance || '-'} h
        </span>
      </div>
    );
  };

  const statusComponent = () => {
    if (loading) {
      return null;
    }
    return employeeBasicInfoOk(employee) ? null : (
      <div className='flex justify-start flex-1 ml-3'>
        <ExclamationCircleIcon className='justify-start w-6 h-6 text-red-600' />
      </div>
    );
  };

  return (
    <div>
      <EmptyPageLayout
        title={constructTitle()}
        titleComponent={statusComponent()}
        rightComponent={currentBalance()}
      >
        {loading ? (
          <div className='flex justify-center mt-10'>
            <Spinner />
          </div>
        ) : (
          <Tabs
            items={[
              {
                name: 'Overview',
                component: (
                  <EmployeeOverview
                    employee={employee}
                    name={employeeFullname(employee)}
                    id={id || ''}
                  />
                )
              },
              {
                name: 'Projects',
                component: (
                  <EmployeeProjects
                    assignments={employee.assignments}
                    onReloadNeeded={() => refetch()}
                  />
                )
              },
              { name: 'Details', component: <EmployeeDetails employee={employee} /> }
            ]}
          />
        )}
      </EmptyPageLayout>
    </div>
  );
};
