import {
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  FieldValues,
  RegisterOptions,
  Control,
  Controller
} from 'react-hook-form';
import { Select, SelectValue } from './Select';

export type DateRangeInputProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  values: SelectValue[];
};

export const SelectFormInput = <TFormValues extends FieldValues>({
  label,
  className,
  name,
  register,
  control,
  rules,
  errors,
  values
}: DateRangeInputProps<TFormValues>) => {
  return (
    <Controller
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <div aria-live='polite'>
            <label className='text-gray-600 font-medium'>
              {label} {errors?.period && <span> - This field is required</span>}
            </label>
            <Select
              name={'ss'}
              onChange={(name, val) => {
                console.log('onChange', name, val);
                onChange(val);
              }}
              values={values}
            />
          </div>
        );
      }}
      control={control}
      name={name}
    />
  );
};
