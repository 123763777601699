import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';

interface ModalDialogProps {
  title: string;
  description: string;
  cancelLabel?: string;
  actionLabel?: string;
  isOpen?: boolean;
  icon?: JSX.Element;
  onClose?(): void;
  onAction?(): void;
}

export const ModalDialog = ({
  title,
  description,
  actionLabel,
  cancelLabel,
  isOpen,
  icon,
  onClose,
  onAction
}: ModalDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };
  const handleOnAction = () => {
    handleOnClose();
    if (onAction !== undefined) {
      onAction();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={handleOnClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10'>
                    {icon}
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>{description}</p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  {actionLabel && (
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md border border-transparent bg-royal-dark-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => handleOnAction()}
                    >
                      {actionLabel}
                    </button>
                  )}
                  {cancelLabel && (
                    <button
                      type='button'
                      className='outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm'
                      onClick={() => handleOnClose()}
                      ref={cancelButtonRef}
                    >
                      {cancelLabel}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
