import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Customers, Projects, useAddProjectMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { SideDrawerSubmitButtons } from '../Drawers/SideDrawerSubmitButtons';
import { DateRangeInput } from '../Inputs/DateRangeInput';
import { SelectFormInput } from '../Inputs/SelectFormInput';
import { TextInput } from '../Inputs/TextInput';
import { EmployeeSearch } from '../Search/EmployeeSearch';

type ProjectAddFormProps = {
  customer: Customers;
  onComplete: () => void;
};
export const ProjectAddForm = ({ customer, onComplete }: ProjectAddFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<Projects>({
    shouldUnregister: true
  });

  const [addProject] = useAddProjectMutation({
    onError: (err: any) => {
      console.log('useAddProjectMutation::err', err);
      setInProgress(false);
    },
    onCompleted: (_resp: any) => {
      setInProgress(false);
      onComplete();
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onFormSubmit = (data: any) => {
    const p = {
      name: data.name,
      start_date: data.period.startDate,
      end_date: data.period.endDate,
      customer_id: customer.id,
      type: data.type ? data.type.label.toLowerCase() : 'billable',
      po: data.po,
      sales_person: data.sales_person?.id
    };
    setInProgress(true);
    addProject({ variables: { ...p } });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className='p-6 space-y-6'>
      <TextInput<any>
        name='name'
        register={register}
        label={'Project name'}
        type={'string'}
        rules={{ required: true }}
        errors={errors}
      />
      <TextInput<any>
        name='po'
        register={register}
        label={'PO'}
        type={'string'}
        rules={{ required: false }}
        errors={errors}
      />
      <DateRangeInput<any>
        name='period'
        register={register}
        label={'Project duration'}
        errors={errors}
        control={control}
      />
      {
        // TODO: make this configurable
        // Maybe in the customer creation UI allow to this
      }
      {customer.is_own ? (
        <SelectFormInput<any>
          label='Project type'
          name='type'
          register={register}
          control={control}
          values={[
            { label: 'Company', value: 1 },
            { label: 'Sickleave', value: 2 },
            { label: 'Holiday', value: 3 },
            { label: 'Unpaid', value: 4 }
          ]}
        />
      ) : (
        <EmployeeSearch<any>
          name='sales_person'
          register={register}
          label={'Sales person'}
          errors={errors}
          control={control}
          rules={{ required: true }}
        />
      )}
      <SideDrawerSubmitButtons inProgress={inProgress} submitDisabled={!isValid} />
    </form>
  );
};
