import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { DateInput } from '../../components/Inputs/DateInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { Employee, useAddBalanceCorrectionMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

type BalanceCorrectionEditFormProps = {
  employee: Employee;
  onComplete: () => void;
};

export const BalanceCorrectionEditForm = ({
  employee,
  onComplete
}: BalanceCorrectionEditFormProps) => {
  const { dispatch } = useContext(SystemUiContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<any>({
    shouldUnregister: true
  });

  const [addBalanceCorrection] = useAddBalanceCorrectionMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onBalanceSubmit = (data: any) => {
    console.log('onBalanceSubmit', data);
    addBalanceCorrection({
      variables: {
        data: [
          {
            employee_id: employee.id,
            day: data.day.startDate,
            comment: data.comment,
            hours: data.hours
          }
        ]
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onBalanceSubmit)} className='flex flex-1 flex-col justify-between'>
      <div className='px-4 py-4 sm:px-6'>
        <DateInput<any>
          name='day'
          register={register}
          label={'Day'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
          control={control}
        />
        <TextInput<any>
          name='comment'
          register={register}
          label={'Comment'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
        <TextInput<any>
          name='hours'
          type='decimal'
          register={register}
          label={'Correction value'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
      </div>
      <SideDrawerSubmitButtons />
    </form>
  );
};
