import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { DateRangeInput } from '../../components/Inputs/DateRangeInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { EmployeeSearch } from '../../components/Search/EmployeeSearch';
import { Assignments, Projects, useAddAssignmentToProjectMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

type ProjectEmployeeAddFormProps = {
  onComplete: () => void;
  project: Projects;
};

export const ProjectEmployeeAddForm = ({ onComplete, project }: ProjectEmployeeAddFormProps) => {
  const { dispatch } = useContext(SystemUiContext);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [addAssignmentToProject] = useAddAssignmentToProjectMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onSubmit = (data: any) => {
    setInProgress(true);
    const ass = {
      ...data,
      start_date: data.period.startDate,
      end_date: data.period.endDate,
      project_id: project.id,
      employee_id: data.employee.id,
      po: data.po
    };

    addAssignmentToProject({
      variables: {
        ...ass
      }
    });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Assignments>({
    shouldUnregister: true
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-1 flex-col justify-between space-y-5 p-6'
    >
      <EmployeeSearch<any>
        name='employee'
        register={register}
        label={'Employee'}
        errors={errors}
        control={control}
      />
      <TextInput<any>
        name='po'
        register={register}
        label={'PO'}
        type={'string'}
        rules={{ required: false }}
        errors={errors}
      />
      <DateRangeInput<any>
        name='period'
        register={register}
        label={'Assignment duration'}
        errors={errors}
        control={control}
      />
      <TextInput<any>
        name='allocation'
        register={register}
        label={'Allocation (%)'}
        type={'number'}
        step={0.01}
        rules={{ required: true }}
        errors={errors}
      />
      <TextInput<any>
        name='external_price'
        register={register}
        label={'External price (€)'}
        type={'number'}
        step={0.01}
        rules={{ required: true }}
        errors={errors}
      />
      <TextInput<any>
        name='internal_price'
        register={register}
        label={'Internal price (€)'}
        type={'number'}
        step={0.01}
        rules={{ required: true }}
        errors={errors}
      />
      <TextInput<any>
        name='comment'
        register={register}
        multiline={true}
        label={'Comment'}
        type={'string'}
        errors={errors}
      />
      <SideDrawerSubmitButtons inProgress={inProgress} />
    </form>
  );
};
