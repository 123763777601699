import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatars/Avatar';
import { EmployeeAvatarList } from '../../components/Avatars/EmployeeAvatarList';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { Spinner } from '../../components/Spinner/Spinner';
import { Panel, Panels } from '../../components/Tabs/Panels';
import { Customers, Projects, useProjectsQuery } from '../../generated/graphql';
import { customerLogoUrl } from '../../models/ProjectTypes';
import { collectEmployees } from '../../utils/type';
import { ProjectDetails } from './ProjectDetails';
import { ProjectEmployees } from './ProjectEmployees';
import { ProjectReport } from './ProjectReport';

export const ProjectPage = () => {
  let { id } = useParams();

  const { data, loading } = useProjectsQuery({
    variables: {
      where: {
        id: { _eq: id }
      }
    }
  });

  const constructTitle = (): any => {
    if (loading) {
      return <span className='text-2xl lg:text-3xl font-bold my-11'>Loading</span>;
    } else if (project) {
      return (
        <span className='text-2xl lg:text-3xl font-bold my-11'>
          <Avatar className='mr-5' size={10} url={customerLogoUrl(project.customer as Customers)} />
          {project.customer?.name} <span> {'\u2022'} </span> {project.name}
        </span>
      );
    } else {
      return <div>Not found...</div>;
    }
  };

  const rightSideControls = () => {
    return <EmployeeAvatarList size={8} employees={collectEmployees(project?.assignments || [])} />;
  };

  const projects = (data?.projects || []) as Projects[];
  const project = projects[0];

  const [activePanel, setActivePanel] = useState<typeof Panel>();
  const [firstPanelRef, setFirstPanelRef] = useState();

  useEffect(() => setActivePanel(firstPanelRef), [firstPanelRef]);

  return (
    <div>
      <EmptyPageLayout titleComponent={constructTitle()} rightComponent={rightSideControls()}>
        {loading ? (
          <div className='flex justify-center mt-10'>
            <Spinner />
          </div>
        ) : (
          <Panels activePanel={activePanel} onActivePanelChange={setActivePanel}>
            <Panel ref={setFirstPanelRef}>
              <ProjectEmployees projectId={id || ''} />
            </Panel>
            <Panel>
              <ProjectReport project={project} />
            </Panel>
            <Panel>
              <ProjectDetails project={project} />
            </Panel>
          </Panels>
        )}
      </EmptyPageLayout>
    </div>
  );
};
