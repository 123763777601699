import React, { useReducer } from 'react';
import { createContext } from 'react';

export interface SystemUiState {
  drawer: {
    open: boolean;
    content: React.ReactNode;
    title: string;
    description: string;
  };
}

interface SystemUiCtx {
  state: SystemUiState;
  dispatch?: React.Dispatch<Action>;
}

interface SystemUiContextProps {
  children?: React.ReactNode;
}

const initialState: SystemUiState = {
  drawer: {
    open: false,
    content: null,
    title: 'Dummy title',
    description: 'Dummy description'
  }
};

const SystemUi = ({ children }: SystemUiContextProps): JSX.Element => {
  const [state, dispatch] = useReducer(SystemUiReducer, initialState);
  return (
    <SystemUiContext.Provider value={{ state, dispatch }}>{children}</SystemUiContext.Provider>
  );
};

export const SystemUiContext = createContext<SystemUiCtx>({ state: initialState });

export default SystemUi;

export enum SystemUiActions {
  SHOW_DRAWER = 'SHOW_DRAWER'
}

export type Action = {
  type: SystemUiActions;
  payload: { [key: string]: any };
};

const SystemUiReducer = (state: SystemUiState, action: Action) => {
  switch (action.type) {
    case SystemUiActions.SHOW_DRAWER: {
      return {
        ...state,
        drawer: {
          open: action.payload.open,
          content: action.payload.content,
          title: action.payload.title,
          description: action.payload.description
        }
      };
    }
    default:
      return state;
  }
};
