type HellonSymbolProps = {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
};
export const HellonSymbol = ({ className }: HellonSymbolProps) => {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1350 1350'>
      <path d='m995.83,637.91v-353.6c-239.37,9.43-448.49,69.5-626.35,181.89V4.42H4.49v830.41h.4v478.53c266.79-429.83,647.92-656.39,990.94-675.45m349.37,0h-349.37c0,224.44-137.63,343.02-366.31,343.02v364.19c434.06,0,715.68-285.85,715.68-707.21' />
    </svg>
  );
};
