import { AekaLogo } from '../logos/AekaLogo';

export const NoSuperPowers = () => {
  return (
    <div className='flex h-screen min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8  bg-login-screen bg-no-repeat bg-cover bg-center bg-fixed'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='flex h-48 justify-center align-middle text-center'>
          <AekaLogo className='fill-white' />
        </div>

        <div className='flex flex-col'>
          <h2 className='mt-20 text-center text-3xl font-bold tracking-tight'>
            Aww snap .. you lack the power
          </h2>
          <h2 className='mt-1 mb-12 text-center text-xl font-normal tracking-tight'>
            Making time tracking f*ucking Graend
          </h2>
        </div>
      </div>
    </div>
  );
};
