import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';

export type DateInputProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  placeholder?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
};

export const DateInput = <TFormValues extends FieldValues>({
  label,
  className,
  placeholder,
  name,
  register,
  control,
  rules,
  errors
}: DateInputProps<TFormValues>) => {
  return (
    <Controller
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <div aria-live='polite'>
            <label className='text-gray-600 font-medium'>
              {label} {errors?.day && <span> - This field is required</span>}
            </label>
            <Datepicker
              placeholder={placeholder}
              asSingle={true}
              useRange={false}
              {...register(name)}
              inputClassName={
                'border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-700'
              }
              primaryColor={'blue'}
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              displayFormat={'DD.MM.YYYY'}
            />
          </div>
        );
      }}
      control={control}
      name={name}
    />
  );
};
