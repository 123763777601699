import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';
import { Toggle } from './Toggle';

export type ToggleFormInputProps<TFormValues extends FieldValues> = {
  label: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
};

export const ToggleFormInput = <TFormValues extends FieldValues>({
  label,
  name,
  register,
  control,
  rules,
  errors
}: ToggleFormInputProps<TFormValues>) => {
  const err = errors ? errors[name] : null;
  return (
    <Controller
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={'flex align-middle justify-between mt-10'} aria-live='polite'>
            <label className='text-gray-600 font-medium'>
              {label} {err && <span> - This field is required</span>}
            </label>
            <Toggle onChange={onChange} selected={value} />
          </div>
        );
      }}
      control={control}
      name={name}
    />
  );
};
