import {
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  FieldValues,
  RegisterOptions
} from 'react-hook-form';

export type Editable = {};

export type TextInputProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  multiline?: boolean;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & React.InputHTMLAttributes<HTMLElement>;

export const TextInput = <TFormValues extends FieldValues>({
  name,
  label,
  multiline = false,
  className,
  rules,
  register,
  errors,
  ...props
}: TextInputProps<TFormValues>): JSX.Element => {
  return (
    <div className={className} aria-live='polite'>
      <label className='text-gray-600 font-medium'>
        {label}{' '}
        {errors?.[name] && (
          <span>
            {' '}
            - {errors?.[name].message ? errors?.[name].message : 'This field is required'}
          </span>
        )}
      </label>
      {multiline ? (
        <textarea
          {...register(name, rules)}
          className='border-solid h-32 border-gray-300 border py-2 px-4 w-full rounded text-gray-700 resize-none'
          {...props}
          autoComplete={'false'}
        />
      ) : (
        <input
          {...register(name, rules)}
          className='border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-700'
          {...props}
          autoComplete={'false'}
        />
      )}
    </div>
  );
};

export const createInputs = <T extends Editable>(
  a: T,
  register: UseFormRegister<T>,
  errors: Partial<DeepMap<T, FieldError>>
): JSX.Element[] => {
  let t: keyof T;
  const items: JSX.Element[] = [];
  for (t in a) {
    items.push(
      <TextInput<T>
        name={t as any}
        register={register}
        label={t as string}
        className='space-y-1 pt-1 pb-2'
        rules={{ required: true }}
        errors={errors}
      />
    );
  }
  return items;
};
