import { add, format } from 'date-fns';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { DateRangeInput } from '../../components/Inputs/DateRangeInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { Salary_Coeffs, useAddSalaryCoeffsMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

type SalaryCoeffsAddFormProps = {
  onComplete: () => void;
};
export const SalaryCoeffsAddForm = ({ onComplete }: SalaryCoeffsAddFormProps) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { dispatch } = useContext(SystemUiContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Salary_Coeffs>({
    shouldUnregister: true
  });

  const [addSalaryCoeffs] = useAddSalaryCoeffsMutation({
    onError: (err: any) => {
      console.error(err);
      setInProgress(false);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const periodToPostgreSQLRange = (period: any) => {
    const ed = add(Date.parse(period.endDate), { days: 1 });
    return `[${period.startDate},${format(ed, 'yyyy-MM-dd')})`;
  };

  const onSubmit = (data: any) => {
    const formattedPeriod = periodToPostgreSQLRange(data.period);
    const { period, bonus_percentage, ...rest } = data;
    const payload = { ...rest, period: formattedPeriod, bonus_percentage: bonus_percentage / 100 };
    setInProgress(true);
    addSalaryCoeffs({ variables: { data: [{ ...payload }] } });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 flex-col justify-between'>
      <div className='px-4 py-4 sm:px-6'>
        <DateRangeInput
          name='period'
          register={register}
          label={'Period'}
          className='space-y-1 pt-1 pb-2'
          errors={errors}
          control={control}
        />
        <TextInput<Salary_Coeffs>
          name='base_salary'
          register={register}
          label={'Base salary'}
          type={'number'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
        <TextInput<Salary_Coeffs>
          name='bonus_percentage'
          register={register}
          label={'Bonus percentage'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
        <TextInput<Salary_Coeffs>
          name='company_side_cost_coef'
          register={register}
          label={'Company side costs'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
        <TextInput<Salary_Coeffs>
          name='day_wage'
          register={register}
          label={'Daily wage'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
        <TextInput<Salary_Coeffs>
          name='hourly_wage'
          register={register}
          label={'Hourly wage'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: true }}
          errors={errors}
        />
      </div>
      <SideDrawerSubmitButtons inProgress={inProgress} />
    </form>
  );
};
