import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { DateInput } from '../../components/Inputs/DateInput';
import { ToggleFormInput } from '../../components/Inputs/ToggleFormInput';
import { Employee, useUpdateEmployeeMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

type EmployeeEditFormProps = {
  employee: Employee;
  onComplete: () => void;
};

export const EmployeeEditForm = ({ employee, onComplete }: EmployeeEditFormProps) => {
  const { dispatch } = useContext(SystemUiContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<any>({
    shouldUnregister: true,
    defaultValues: {
      non_billable_employee: employee?.metadata?.non_billable_employee
    }
  });

  const [updateEmployee, { loading }] = useUpdateEmployeeMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onEmployeeSubmit = (data: any) => {
    updateEmployee({
      variables: {
        id: employee.id,
        data: {
          employment_start: data.employment_start?.startDate,
          employment_end: data.employment_end?.startDate,
          metadata: {
            non_billable_employee: data.non_billable_employee
          }
        }
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onEmployeeSubmit)}
      className='flex flex-1 flex-col justify-between'
    >
      <div className='px-4 py-4 sm:px-6'>
        <DateInput<any>
          name='employment_start'
          placeholder={employee.employment_start}
          register={register}
          label={'Employment start'}
          className='space-y-1 pt-1 pb-2'
          rules={{ required: false }}
          errors={errors}
          control={control}
        />
        <div className='h-8'></div>

        <DateInput<any>
          name='employment_end'
          placeholder={employee.employment_end}
          register={register}
          label={'Employment end'}
          className='space-y-1 pt-1 pb-2 mt-10'
          rules={{ required: false }}
          errors={errors}
          control={control}
        />

        <ToggleFormInput<any>
          label={'Non billable employee'}
          name='non_billable_employee'
          register={register}
          rules={{ required: false }}
          errors={errors}
          control={control}
        />
      </div>
      <SideDrawerSubmitButtons inProgress={loading} submitDisabled={!isValid} />
    </form>
  );
};
