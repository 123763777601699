import * as d3 from 'd3';
import { addMonths, subMonths } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { firstDayOfMonthAsISO } from '../../utils/formatters';

const useResize = (ref: any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, [ref]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, handleResize]);

  return { width, height };
};

type ChartProps = {
  transposed: any[];
};

export const Chart = ({ transposed }: ChartProps) => {
  const d3Ref = useRef<any>();

  const chartContainerRef = useRef<any>();
  const { width, height } = useResize(chartContainerRef);

  const projectNames: string[] = useMemo(
    () => [...d3.group(transposed, (d: any) => d.project).keys()],
    [transposed]
  );

  const colors = d3.schemeCategory10;
  const projectColorMap = projectNames.reduce((p: any, c, i: number) => {
    p[c] = colors[i];
    return p;
  }, {});

  useEffect(() => {
    const margin = {
      top: 50,
      right: 80,
      bottom: 80,
      left: 80
    };

    // append the svg object to the body of the page
    const svg = d3
      .select(d3Ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g');

    svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    const byMonth: any = d3.rollup(
      transposed,
      (v: any) => {
        return {
          billing: d3.sum(v, (d: any) => d.billing),
          hours: d3.sum(v, (d: any) => d.hours)
        };
      },
      (d: any) => Date.parse(firstDayOfMonthAsISO(d.day)),
      (d: any) => d.project
    );

    const max = d3.max(
      d3.rollup(
        byMonth,
        (v: any) => {
          return d3.sum(v[0][1].values(), (d: any) => d.billing);
        },
        (d: any) => d[1]
      ),
      (sum: any) => sum[1]
    );

    const maxHours = d3.max(
      d3.rollup(
        byMonth,
        (v: any) => {
          return d3.sum(v[0][1].values(), (d: any) => d.hours);
        },
        (d: any) => d[1]
      ),
      (sum: any) => sum[1]
    );

    const monthExtentOrg: any = d3.extent(byMonth, (d: any) => d[0]);
    const monthExtent = [subMonths(monthExtentOrg[0], 1), addMonths(monthExtentOrg[1], 1)];
    console.log(monthExtent);
    const months: any[] = d3.timeMonths(monthExtent[0], monthExtent[1]);

    const dateBand = d3
      .scaleBand()
      .domain(months)
      .range([margin.left, width - margin.right])
      .padding(0.3);

    const dateScale = d3
      .scaleTime()
      .domain(monthExtent)
      .range([margin.left, width - margin.right])
      .nice();

    const monthlySeries = d3
      .stack()
      .keys(projectNames)
      .value((row: any, key: any) => {
        return row[1].get(key)?.billing || 0;
      })(byMonth)
      .map((d, i) => {
        d.map((d: any) => {
          d.key = projectNames[i];
          return d;
        });
        return d;
      });

    const y = d3
      .scaleLinear()
      .domain([0, max] as any[])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const yRightHours = d3
      .scaleLinear()
      .domain([0, maxHours])
      .range([height - margin.bottom, margin.top])
      .nice();

    var xAxis = d3.axisBottom(dateScale);

    // Generate tick values for months
    xAxis.ticks(d3.timeMonth.every(2));

    // Format the ticks with month and year
    xAxis.tickFormat((date: any) => {
      if (date.getMonth() === 0) {
        return d3.timeFormat('%Y')(date);
      }
      return d3.timeFormat('%b')(date); // You can customize the format as needed
    });

    svg
      .append('text')
      .attr('class', 'y left label')
      .attr('text-anchor', 'middle')
      .attr('y', 6)
      .attr('dy', '.75em')
      .attr('transform', `translate(${width - 10}, ${height / 2})rotate(90)`)
      .text('Reported hours');

    // Append the x-axis to your chart
    var xAxisGroup: any = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(xAxis);

    // Highlight the year changes
    xAxisGroup
      .selectAll('.tick text')
      .filter((d: any, i: any) => {
        // Check if the tick represents the start of a new year
        if (i - 1 < 0) {
          return false;
        }
        return d.getFullYear() !== xAxisGroup.selectAll('.tick text').data()[i - 1].getFullYear();
      })
      .attr('font-size', '12') // Make the text bold
      .attr('font-weight', 'bold'); // Make the text bold

    svg
      .append('g')
      .attr('transform', `translate(${width - margin.left},0)`)
      .call(d3.axisLeft(yRightHours))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('transform', 'translate(40, 0)');

    if (max > 0) {
      svg
        .append('text')
        .attr('class', 'y left label')
        .attr('text-anchor', 'middle')
        .attr('y', 6)
        .attr('dy', '.75em')
        .attr('transform', `translate(10, ${height / 2})rotate(-90)`)
        .text('Customer billing');

      svg
        .append('g')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).tickFormat((x: any) => `${(x / 1e3).toFixed(1)}k`))
        .call((g) =>
          g
            .selectAll('.tick line')
            .clone()
            .attr('stroke-opacity', (d) => (d === 1 ? null : 0.2))
            .attr('x2', width - margin.left - margin.right)
        )

        .call((g) => g.select('.domain').remove());

      svg
        .append('g')
        .selectAll('g')
        .data(monthlySeries)
        .enter()
        .append('g')
        .selectAll('rect')
        .data((d) => d)
        .enter()
        .append('rect')
        .attr('x', (d: any, i: any) => {
          const date: any = d.data[0];
          return dateScale(new Date(date)) - dateBand.bandwidth() / 2;
        })
        .attr('width', dateBand.bandwidth() - 1)

        .attr('height', (d) => {
          return y(d[0]) - y(d[1]) || 0;
        })
        .attr('y', (d) => y(d[1]))
        .attr('fill', (d: any) => {
          return projectColorMap[d.key];
        })
        .append('title')
        .text((d) => {
          const xx: any = d as unknown; // typescript fuckery
          const value: any = d.data[1]; // typescript fuckery
          const projectName: string = xx.key;
          return `Project: ${projectName},\nBilling: ${d3.format('.2f')(
            value.get(projectName)?.billing || 0
          )}`;
        });
    }

    svg
      .append('defs')
      .append('marker')
      .attr('id', 'dot')
      .attr('viewBox', [0, 0, 20, 20])
      .attr('refX', 10)
      .attr('refY', 10)
      .attr('markerWidth', 5)
      .attr('markerHeight', 5)
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 10)
      .attr('r', 10)
      .attr('stroke-width', 2)
      .style('stroke', 'black')
      .style('fill', 'red');

    /*
    svg
      .append('path')
      .attr('transform', `translate(${dateBand.bandwidth() / 2},0)`)
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('marker-start', 'url(#dot)')
      .attr('marker-mid', 'url(#dot)')
      .attr('marker-end', 'url(#dot)')
      .attr('stroke-width', 1)
      .attr('d', hoursLine(byMonth as any));
      */

    // Draw the line
    svg
      .selectAll('.line')
      .data(monthlySeries)
      .enter()
      .append('path')
      .attr('transform', `translate(${dateBand.bandwidth() / 2},0)`)

      .attr('fill', 'none')
      .attr('stroke', (d: any) => {
        return d3.color(projectColorMap[d.key])?.darker() as any;
      })

      .attr('stroke-width', 2)
      .attr('d', (d: any) => {
        return d3
          .line()
          .x(function (dx: any) {
            const date: any = dx.data[0];
            return dateScale(new Date(date)) - dateBand.bandwidth() / 2;
          })
          .y((dy: any) => {
            return yRightHours(dy.data[1].get(dy.key)?.hours || 0) as any;
          })(d);
      });
  }, [width, height, transposed, projectNames, projectColorMap]);

  const legend = () => {
    return Object.keys(projectColorMap).map((k) => {
      return (
        <div key={k} className={`flex m-5 `}>
          <div>{k}</div>
          <div style={{ background: projectColorMap[k] }} className='ml-5 w-5 h-5' />
        </div>
      );
    });
  };

  return (
    <div className='w-full flex-col h-[500px] shadow-lg' ref={chartContainerRef}>
      <div className='w-full flex justify-center flex-wrap'>{legend()}</div>
      <svg width={width} height={height} className='flex-auto' id='barchart' ref={d3Ref} />
    </div>
  );
};
