import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { Time_Entries } from '../../generated/graphql';
import { TimeEntrySummary, groupByProject } from '../../models/TimeEntryTypes';
import { monthName } from '../../utils/formatters';

type HoursPdfExportProps = {
  entries: Time_Entries[];
  name: string;
  month: Date;
  totalHours: number;
};

export const HoursPdfExport = ({ entries, name, month, totalHours }: HoursPdfExportProps) => {
  const cellWidth = 80;
  const borderRadius = 3;
  const rowPadding = 6;
  const rowTextLeftPadding = 20;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      paddingVertical: 20,
      paddingHorizontal: 40,
      margin: 0
    },
    header: {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    nameContainer: {
      flexDirection: 'column',
      margin: 0,
      padding: 0
    },
    name: {
      fontSize: 18,
      margin: 0,
      padding: 0
    },
    project: {
      fontSize: 12
    },
    monthContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: 1,
      borderColor: '#cc000000',
      borderRadius: borderRadius,
      marginVertical: 10
    },

    month: {
      fontSize: 14,
      margin: 10
    },

    totalHours: {
      fontSize: 16,
      margin: 10
    },

    tableTitle: {
      flexDirection: 'row',
      backgroundColor: '#000000',
      borderRadius: borderRadius,
      marginTop: 20
    },

    tableTitleText: {
      fontSize: 10,
      color: 'white',
      paddingVertical: 10,
      paddingHorizontal: rowTextLeftPadding,
      width: cellWidth
    },

    tableTitleTextGrow: {
      fontSize: 10,
      color: 'white',
      paddingVertical: 10,
      paddingHorizontal: rowTextLeftPadding,
      flexGrow: 1
    },
    rowElement: {
      backgroundColor: '#EBEBEB',
      marginTop: 5,
      flexDirection: 'row',
      borderRadius: borderRadius,
      flexWrap: 'nowrap'
    },
    rowElementText: {
      fontSize: 10,
      color: 'black',
      padding: rowPadding,
      paddingHorizontal: rowTextLeftPadding,
      width: cellWidth,
      flexGrow: 0,
      minWidth: cellWidth
    },
    rowElementTextGrow: {
      fontSize: 10,
      color: 'black',
      padding: rowPadding,
      flexGrow: 1,
      maxWidth: 270,
      flexWrap: 'wrap'
    },

    tableTitleTextGrow1: {
      fontSize: 10,
      color: 'white',
      paddingVertical: 10,
      paddingHorizontal: rowTextLeftPadding,
      flexGrow: 1
    },
    tableTitleTextGrow2: {
      fontSize: 10,

      color: 'white',
      paddingVertical: 10,
      paddingHorizontal: rowTextLeftPadding,
      flexGrow: 2,
      maxWidth: 300
    },
    rowElementTextGrow1: {
      fontSize: 10,
      color: 'black',
      padding: rowPadding,
      paddingHorizontal: rowTextLeftPadding,
      flexGrow: 1
    },
    rowElementTextGrow2: {
      fontSize: 10,

      color: 'black',
      padding: rowPadding,
      paddingHorizontal: rowTextLeftPadding,
      flexGrow: 2,
      maxWidth: 300
    }
  });

  const start = format(startOfMonth(month), 'd.M.');
  const last = format(lastDayOfMonth(month), 'd.M.yyyy');
  const range = `${monthName(month)} ${month.getFullYear()} ${start} - ${last}`;

  const groupedEntries = groupByProject(entries || []);

  const subtitle = `Report for ${
    entries.length > 0 ? entries[0].assignment?.project?.customer?.name || '' : ''
  }`;
  return (
    <Document>
      <Page size='A4' wrap={false} style={styles.page}>
        <View style={styles.header}>
          <View style={styles.nameContainer}>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.project}>{subtitle}</Text>
          </View>
          <Image style={{ width: 100, height: 20 }} src={'/assets/hellon_logo.png'} />
        </View>

        <View style={styles.monthContainer}>
          <Text style={styles.month}>{range}</Text>
          <Text style={styles.totalHours}> {totalHours} h</Text>
        </View>

        <View style={styles.tableTitle}>
          <Text style={styles.tableTitleTextGrow2}>Project</Text>
          <Text style={styles.tableTitleTextGrow1}>Total hours</Text>
        </View>
        {groupedEntries.map((e: TimeEntrySummary) => (
          <View key={e.id} style={styles.rowElement}>
            <Text style={styles.rowElementTextGrow2}>{e.project_name}</Text>
            <Text style={styles.rowElementTextGrow1}>{e.hours}</Text>
          </View>
        ))}

        <View style={styles.tableTitle}>
          <Text style={styles.tableTitleText}>Day</Text>
          <Text style={styles.tableTitleText}>Project</Text>
          <Text style={styles.tableTitleText}>Hours</Text>
          <Text style={styles.tableTitleTextGrow}>Comment</Text>
        </View>

        {entries.map((e: Time_Entries) => (
          <View key={e.id} style={styles.rowElement}>
            <Text style={styles.rowElementText}>{e.day}</Text>
            <Text style={styles.rowElementText}>{e.assignment?.project?.name}</Text>
            <Text style={styles.rowElementText}>{e.hours}</Text>
            <Text style={styles.rowElementTextGrow}>{e.comment}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};
