import { Area } from 'react-easy-crop/types';

export const cropImage = async (imageUrl: string, crop: Area, mime: string) => {
  const inputImage: HTMLImageElement = new Image();

  const result = new Promise<HTMLCanvasElement>((resolve, reject) => {
    inputImage.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = crop.width;
      canvas.height = crop.height;

      if (ctx == null) {
        return null;
      }
      ctx.drawImage(
        inputImage,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );
      resolve(canvas);
    };
    inputImage.onerror = (e) => {
      console.error(e);
      reject(e);
    };
    inputImage.src = imageUrl;
  });

  const renderResult = await result;

  return new Promise<Blob>((resolve, reject) => {
    renderResult?.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject();
        }
      },
      mime,
      1
    );
  });
};
