import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { ChevronDoubleRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../../components/Avatars/Avatar';
import { EmployeeAvatarList } from '../../components/Avatars/EmployeeAvatarList';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { ProjectAddForm } from '../../components/Projects/ProjectAddForm';
import { Search } from '../../components/Search/Search';
import { Customers, Projects as Project, useCustomersQuery } from '../../generated/graphql';
import {
  activeProjects,
  customerLogoUrl,
  filterCustomersProjects,
  projectsFor,
  sortCustomers
} from '../../models/ProjectTypes';
import { MonthContext } from '../../providers/MonthContext';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { formatDate, monthName } from '../../utils/formatters';
import { collectEmployees } from '../../utils/type';

import { CustomerAddForm } from './CustomerAddForm';

export const Projects = () => {
  const { dispatch } = useContext(SystemUiContext);
  const { data, refetch } = useCustomersQuery();
  const { selectedMonth } = useContext(MonthContext);
  const [filter, setFilter] = useState<string>('');

  const customers = useMemo(() => (data?.customers || []) as Customers[], [data?.customers]);

  const selectedMonthFilter = (project: Project): boolean => {
    return activeProjects(project, selectedMonth);
  };

  const rightSideControls = () => {
    return (
      <div className='inline-flex items-center'>
        <button
          className='inline-flex bg-transparent px-6 border border-transparent text-base font-medium rounded-full shadow-sm text-gold  hover:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent'
          onClick={() => {
            dispatch?.({
              type: SystemUiActions.SHOW_DRAWER,
              payload: {
                open: true,
                title: 'Add customer',
                description: `Add a new money making customer`,
                content: <CustomerAddForm onComplete={() => refetch()} />
              }
            });
          }}
        >
          <PlusIcon className='w-5 h-5 mr-5' />
          <span className='flex-1 text-center'>Add customer</span>
        </button>
      </div>
    );
  };

  const p = projectsFor(customers, selectedMonthFilter);

  const filtered = filterCustomersProjects(p, filter);
  const sorted = sortCustomers(filtered);

  const projects = (projects: Project[], customerId: string) => {
    return projects.map((project) => {
      return (
        <div className='grid grid-cols-3 content-center items-center border-b-2 border-l-2 border-slate-100 border-r-2 pt-3 pb-3 last:rounded-b-2xl'>
          <div className='flex'>
            <div className='content-center ml-16 mr-auto'>
              <Link to={`/projects/${project.id}`}>{project.name}</Link>
            </div>
          </div>
          <div className='flex flex-grow justify-self-center text-slate-500'>
            <div>{formatDate(project.start_date)}</div>
            <ChevronDoubleRightIcon className='h-3 text-slate-600 ml-3 mr-3 self-center' />
            <div className='arrow'></div>
            <div className='mr-auto'>{formatDate(project.end_date)}</div>
          </div>
          <div className='flex justify-end content-center mr-3'>
            <EmployeeAvatarList employees={collectEmployees(project.assignments)} size={7} />
          </div>
        </div>
      );
    });
  };

  const onUpdate = () => {
    refetch();
  };

  return (
    <EmptyPageLayout title='Projects by customer' rightComponent={rightSideControls()}>
      <div className='flex flex-shrink-0 justify-between'>
        <div className='w-96 text-2xl font-normal self-center'>{`Active in ${monthName(
          selectedMonth
        )} ${selectedMonth.getFullYear()}`}</div>
        <Search onFilterUpdated={setFilter} placeholder={'projects by name'} />
      </div>
      <div className='mt-20'>
        {sorted.map((cust: Customers) => (
          <div key={cust.id} className='mb-10'>
            <div className='flex justify-start h-16 bg-[#f3f4f6] text-slate-900 rounded-t-2xl text-xl font-semibold border-b-2 border-b-slate-200'>
              <Avatar className='content-center ml-4' url={customerLogoUrl(cust)} />
              <div className='ml-3 content-center mr-auto'>
                <Link to={`/customer/${cust.id}`}>{cust.name}</Link>
              </div>
              <PlusCircleIcon
                className='w-7 h-7  self-center mr-3 hover:text-slate-600 cursor-pointer'
                onClick={() => {
                  dispatch?.({
                    type: SystemUiActions.SHOW_DRAWER,
                    payload: {
                      open: true,
                      title: 'Add new project',
                      description: `Add new project for ${cust.name}`,
                      content: <ProjectAddForm customer={cust} onComplete={() => onUpdate()} />
                    }
                  });
                }}
              />
            </div>
            {projects(cust.projects, cust.id)}
          </div>
        ))}
      </div>
    </EmptyPageLayout>
  );
};
