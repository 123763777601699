import { StatsCard } from '../../components/Cards/StatsCard';
import { Employee, Time_Entries, useEmployeeTimeEntriesQuery } from '../../generated/graphql';

import {
  firstDayOfMonthAsISO,
  formatCurrency,
  formatHours,
  lastDayOfMonthAsISO
} from '../../utils/formatters';

import { useContext } from 'react';
import { Avatar } from '../../components/Avatars/Avatar';
import { Spinner } from '../../components/Spinner/Spinner';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { TitleWithSubtitle } from '../../components/Titles/TitleWithSubtitle';
import {
  TimeEntrySummary,
  groupByProject,
  totalBilling,
  totalHours
} from '../../models/TimeEntryTypes';
import { MonthContext } from '../../providers/MonthContext';
import { MonthlyDetailsTable } from './MontlyDetailsTable';

type EmployeeOverviewProps = {
  id: string;
  employee: Employee;
  name: string;
};

export const EmployeeOverview = ({ id, name, employee }: EmployeeOverviewProps) => {
  const { selectedMonth, workingDays } = useContext(MonthContext);

  const { data, loading } = useEmployeeTimeEntriesQuery({
    variables: {
      id: id,
      start: firstDayOfMonthAsISO(selectedMonth),
      end: lastDayOfMonthAsISO(selectedMonth)
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const entries = (data?.time_entries || []) as Time_Entries[];

  const nameColumnRenderer = (item: any) => {
    const entry = item.row.original as TimeEntrySummary;

    return (
      <div className='flex items-center space-x-4'>
        <Avatar size={8} url={entry.logo} />
        <TitleWithSubtitle title={entry.project_name} subtitle={entry.assignment_name} />
      </div>
    );
  };

  const columns = [
    {
      accessorKey: 'assignment',
      header: 'Project',
      cell: nameColumnRenderer
    },
    {
      accessorKey: 'external_price',
      header: 'External price'
    },
    {
      accessorKey: 'internal_price',
      header: 'Internal price'
    },
    {
      accessorKey: 'hours',
      header: 'Hours'
    }
  ];

  const groupedEntries = groupByProject(entries);

  var byDay: { [key: string]: Array<any> } = {};
  byDay = entries.reduce((prev, curr) => {
    if (!prev[curr.day]) {
      prev[curr.day] = [];
    }
    prev[curr.day].push(curr);
    return prev;
  }, byDay);

  return (
    <div>
      {loading ? (
        <div className='flex self-center justify-center'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='grid grid-cols-3 gap-8 mb-12'>
            <StatsCard label='Billing' value={formatCurrency(totalBilling(entries))} />
            <StatsCard
              label='Reported days'
              value={`${Object.keys(byDay).length} / ${workingDays}`}
            />
            <StatsCard label='Reported hours' value={`${formatHours(totalHours(entries))} h`} />
          </div>

          <div className='mb-12'>
            <ExtendableTable columns={columns} data={groupedEntries} title='Project summary' />
          </div>
          <MonthlyDetailsTable employee={employee} entries={entries} month={selectedMonth} />
        </>
      )}
    </div>
  );
};
