import { add, format, parseISO, sub } from 'date-fns';

import { PlusIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';
import {
  currencyColumnGenerator,
  dateColumnGenerator,
  numberColumnGenerator,
  percentColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Content, PanelTab } from '../../components/Tabs/Panels';
import { Salary_Coeffs, usePayrollSettingsQuery } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { SalaryCoeffsAddForm } from './SalaryCoeffsAddForm';

type Transformed_Salary_Coef = {
  from: string;
  to: string;
} & Salary_Coeffs;

export type FormInputProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const PayrollSettings = () => {
  const { dispatch } = useContext(SystemUiContext);

  const { loading, data, refetch } = usePayrollSettingsQuery();

  const parsePostgreSQLRange = (range: string) => {
    let from = parseISO(range.substring(1, range.indexOf(',')));
    let to = parseISO(range.substring(range.indexOf(',') + 1, range.length - 1));

    if (range.charAt(0) === '(') {
      from = add(from, { days: 1 });
    }

    if (range.charAt(range.length - 1) === ')') {
      to = sub(to, { days: 1 });
    }
    return { from: format(from, 'yyyy-MM-dd'), to: format(to, 'yyyy-MM-dd') };
  };

  const transformedData = [];
  if (data && data.salary_coeffs) {
    for (const coeff of data.salary_coeffs) {
      const dates = parsePostgreSQLRange(coeff['period']);
      transformedData.push({ ...dates, ...coeff });
    }
  }

  const columnHelper = createColumnHelper<Transformed_Salary_Coef>();

  const columns = [
    columnHelper.accessor((row) => row.from, {
      id: 'from',
      ...dateColumnGenerator('From')
    }),
    columnHelper.accessor((row) => row.to, {
      id: 'to',
      ...dateColumnGenerator('To')
    }),
    columnHelper.accessor((row) => row.base_salary, {
      id: 'base_salary',
      ...currencyColumnGenerator('Base Salary')
    }),
    columnHelper.accessor((row) => row.bonus_percentage * 100, {
      id: 'bonus_percentage',
      ...percentColumnGenerator('Bonus %')
    }),
    columnHelper.accessor((row) => row.company_side_cost_coef, {
      id: 'company_side_cost_coef',
      ...numberColumnGenerator('Side costs')
    }),
    columnHelper.accessor((row) => row.day_wage, {
      id: 'day_wage',
      ...numberColumnGenerator('Day wage')
    }),
    columnHelper.accessor((row) => row.hourly_wage, {
      id: 'hourly_wage',
      ...numberColumnGenerator('Hourly wage')
    })
  ];

  return (
    <>
      <PanelTab
        action={
          <button
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-royal-dark-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-royal-blue/90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            onClick={() => {
              dispatch?.({
                type: SystemUiActions.SHOW_DRAWER,
                payload: {
                  open: true,
                  title: 'Add new base values',
                  description: `To be used when calculating salaries`,
                  content: <SalaryCoeffsAddForm onComplete={() => refetch()} />
                }
              });
            }}
          >
            <PlusIcon className='mr-5 h-6 w-6' aria-hidden='true' />
            Add new
          </button>
        }
      >
        Salary coeffs
      </PanelTab>
      <Content>
        <ExtendableTable
          columns={columns}
          data={transformedData}
          loading={loading}
          title='Bonus coefficients'
        />
      </Content>
    </>
  );
};
