import { gapi } from 'gapi-script';
import { useEffect, useState } from 'react';
import { Employee } from '../generated/graphql';
import { useLocalStorage } from './LocalStorage';

const scopes = 'https://www.googleapis.com/auth/spreadsheets';
const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
const GOOGLE_TOKEN_EXPIRY = 'google.sheets.tokenExpiry';

export const useGoogleSheetExport = (): [
  boolean,
  (title: string, payrollData: Employee[]) => void,
  string | undefined
] => {
  const [exporting, setExporting] = useState<boolean>(false);
  const [sheetId, setSheetId] = useState<string | undefined>();
  const [tokenExpiryTime, setTokenExpiryTime] = useLocalStorage(GOOGLE_TOKEN_EXPIRY, 0);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId,
        discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        scope: scopes
      });
    };

    gapi.load('client:auth2', initClient);
  }, []);

  const createGoogleSheet = (title: string, values: any[]) => {
    try {
      gapi.client.sheets.spreadsheets
        .create({
          // @ts-expect-error ... the typings cant seem to match
          properties: {
            title: title
          }
        })
        .then((response: any) => {
          const spreadsheetId = response.result.spreadsheetId;
          const sheetData = [
            {
              range: 'Sheet1',
              values: values
            }
          ];

          const body = {
            data: sheetData,
            valueInputOption: 'USER_ENTERED'
          };

          gapi.client.sheets.spreadsheets.values
            .batchUpdate({
              spreadsheetId: spreadsheetId,
              resource: body
            })
            .then((response: any) => {
              const result = response.result;
              console.log(`${result.totalUpdatedCells} cells updated. ${response}`);
              setExporting(false);
              setSheetId(spreadsheetId);
            });
        });
    } catch (err: any) {
      console.error('ERROR' + err.message);
      return;
    }
  };

  const exportSheet = (title: string, data: any[]) => {
    setExporting(true);
    const currentTimeInSeconds = new Date().getTime() / 1000;
    if (tokenExpiryTime < currentTimeInSeconds) {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: scopes,
        prompt: 'none',
        callback: (res: any) => {
          setTokenExpiryTime(currentTimeInSeconds + res.expires_in);
          createGoogleSheet(title, data);
        }
      });
      client.requestAccessToken({ prompt: 'none' });
    } else {
      createGoogleSheet(title, data);
    }
  };
  return [exporting, exportSheet, sheetId];
};
