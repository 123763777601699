import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatars/Avatar';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { CustomerEditForm } from '../../components/Projects/CustomerEditForm';
import { ProjectAddForm } from '../../components/Projects/ProjectAddForm';
import {
  dateColumnGenerator,
  projectNameAvatarGenerator,
  stringColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Content, Panel, PanelButton, PanelTab, Panels } from '../../components/Tabs/Panels';
import { Customers, Projects, useCustomerDetailsQuery } from '../../generated/graphql';
import { customerLogoUrl } from '../../models/ProjectTypes';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { CustomerReports } from './CustomerReports';

export const CustomerPage = () => {
  const { id } = useParams();
  const { dispatch } = useContext(SystemUiContext);

  const { loading, data, refetch } = useCustomerDetailsQuery({
    variables: {
      where: {
        id: {
          _eq: id
        }
      }
    }
  });

  const customers = (data?.customers || []) as Customers[];
  const customer = customers[0] || {};

  /*
  const calculcateHours = (projectId: string): number => {
    const project = customer?.projects_aggregate?.nodes.find((p) => p.id === projectId);

    return (
      project?.assignments.reduce(
        (prev, val) => prev + val.time_entries_aggregate.aggregate?.sum?.hours,
        0
      ) || 0
    );
  };

  const calculcateRevenue = (projectId: string): number => {
    const project = customer?.projects_aggregate?.nodes.find((p) => p.id === projectId);
    return (
      project?.assignments.reduce(
        (prev, val) =>
          prev + val.time_entries_aggregate.aggregate?.sum?.hours * (val.external_price || 0),
        0
      ) || 0
    );
  };
*/
  const rightSideControls = () => {
    return (
      <div className='inline-flex items-center'>
        <button
          className='inline-flex bg-transparent px-6 border border-transparent text-base font-medium rounded-full shadow-sm text-gold  hover:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent'
          onClick={() => {
            dispatch?.({
              type: SystemUiActions.SHOW_DRAWER,
              payload: {
                open: true,
                title: 'Edit customer',
                description: `Edit customer details`,
                content: <CustomerEditForm customer={customer} onComplete={refetch} />
              }
            });
          }}
        >
          <PencilSquareIcon className='w-5 h-5 mr-5' />
          <span className='flex-1 text-center'>Edit customer</span>
        </button>
      </div>
    );
  };

  const addProjectButton = () => {
    return (
      <PanelButton
        icon={<PlusIcon className='w-5 h-5 mr-5' />}
        label='Add project'
        onClick={() => {
          dispatch?.({
            type: SystemUiActions.SHOW_DRAWER,
            payload: {
              open: true,
              title: 'Add project',
              description: `Add a new project for ${customer.name}`,
              content: <ProjectAddForm customer={customer} onComplete={() => refetch()} />
            }
          });
        }}
      />
    );
  };

  const columnHelper = createColumnHelper<Projects>();
  const columns = [
    columnHelper.accessor((row: Projects) => row, {
      id: 'project_name',
      ...projectNameAvatarGenerator()
    }),

    columnHelper.accessor((row: Projects) => row.po, {
      id: 'po',
      ...stringColumnGenerator('PO')
    }),
    columnHelper.accessor((row: Projects) => row.start_date, {
      id: 'start_date',
      ...dateColumnGenerator('Start Date')
    }),
    columnHelper.accessor((row: Projects) => row.end_date, {
      id: 'end_date',
      ...dateColumnGenerator('End Date')
    })
  ];

  const projectList = () => {
    if (customer.projects && customer.projects.length > 0) {
      return <ExtendableTable columns={columns} data={customer.projects} loading={loading} />;
    }
    return <div> No projects yet</div>;
  };

  const constructTitle = (): any => {
    return (
      <span className='text-2xl lg:text-3xl font-bold my-11'>
        <Avatar className='mr-5' size={10} url={customerLogoUrl(customer as Customers)} />
        {customer.name}
      </span>
    );
  };

  const [activePanel, setActivePanel] = useState<typeof Panel>();
  const [firstPanelRef, setFirstPanelRef] = useState();

  useEffect(() => setActivePanel(firstPanelRef), [firstPanelRef]);

  return (
    <EmptyPageLayout titleComponent={constructTitle()} rightComponent={rightSideControls()}>
      <Panels activePanel={activePanel} onActivePanelChange={setActivePanel}>
        <Panel ref={setFirstPanelRef}>
          <PanelTab action={addProjectButton()}>Projects</PanelTab>
          <Content>{projectList()}</Content>
        </Panel>
        <Panel>
          <CustomerReports customer={customer} />
        </Panel>
      </Panels>
    </EmptyPageLayout>
  );
};
