import { PlusIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { Customers, useCustomersQuery } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { CustomerAddForm } from '../Projects/CustomerAddForm';
import CustomerList from '../Projects/CustomerList';

const CustomersPage = () => {
  const { data, refetch } = useCustomersQuery();
  const { dispatch } = useContext(SystemUiContext);

  const d = (data?.customers || []) as Customers[];

  const rightSideControls = () => {
    return (
      <div className='inline-flex items-center'>
        <button
          className='inline-flex bg-transparent px-6 border border-transparent text-base font-medium rounded-full shadow-sm text-gold  hover:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent'
          onClick={() => {
            dispatch?.({
              type: SystemUiActions.SHOW_DRAWER,
              payload: {
                open: true,
                title: 'Add customer',
                description: `Add a new customer to bill`,
                content: <CustomerAddForm onComplete={() => refetch()} />
              }
            });
          }}
        >
          <PlusIcon className='w-5 h-5 mr-5' />
          <span className='flex-1 text-center'>Add customer</span>
        </button>
      </div>
    );
  };
  return (
    <EmptyPageLayout title={'Customers'} rightComponent={rightSideControls()}>
      <CustomerList customers={d} />
    </EmptyPageLayout>
  );
};

export default CustomersPage;
