type AekaLogoProps = {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
};
export const AekaLogo = ({ className, fill, width, height }: AekaLogoProps) => {
  return (
    <svg className={className} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9387 8.37338H9.12781L0 30.6243H4.02607L6.17741 25.1845H15.7662L17.8868 30.6243H22.0358L12.9387 8.37338ZM8.72828 18.6383C9.49661 16.6407 10.2035 14.7352 10.9411 12.6761H11.0025C11.7094 14.6737 12.447 16.6407 13.2154 18.6383L14.5062 21.9883H7.43748L8.72828 18.6383Z'
        fill='inherit'
      />
      <path
        d='M18.8425 30.6243H27.4353V27.1515H20.0237L14.8015 20.6975H25.8986V17.3783H14.8015L13.8228 11.8463H27.128V8.37338H10.8983L18.8425 30.6243Z'
        fill='inherit'
      />
      <path
        d='M9.3275 4.492L10.7219 0.948639L27.1329 0.948639L27.1329 4.492L9.3275 4.492Z'
        fill='inherit'
      />
    </svg>
  );
};
