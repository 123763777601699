import { Link } from 'react-router-dom';
import { Avatar } from '../../components/Avatars/Avatar';
import { GridItem2 } from '../../components/Lists/GridItem2';
import { GridListPlain } from '../../components/Lists/GridListPlain';
import { TitleWithSubtitle } from '../../components/Titles/TitleWithSubtitle';
import { Customers } from '../../generated/graphql';
import { customerLogoUrl, sortCustomers } from '../../models/ProjectTypes';

type CustomerListProps = {
  customers: Customers[];
};
const CustomerList = ({ customers }: CustomerListProps) => {
  const sorted = sortCustomers(customers);
  return (
    <>
      <GridListPlain>
        {sorted.map((customer: Customers, index: number) => (
          <Link to={`/customer/${customer.id}`} key={index}>
            <GridItem2
              key={customer.id}
              center={
                <TitleWithSubtitle
                  title={customer.name}
                  subtitle={`${customer.projects.length} project(s)`}
                />
              }
              left={<Avatar url={customerLogoUrl(customer)} />}
            />
          </Link>
        ))}
      </GridListPlain>
    </>
  );
};

export default CustomerList;
