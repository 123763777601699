import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type ToggleProps = {
  description?: string;
  selected?: boolean;
  onChange: (value: boolean) => void;
};

export const Toggle = ({ description, onChange, selected = false }: ToggleProps) => {
  const [enabled, setEnabled] = useState(selected);

  useEffect(() => {
    onChange(enabled);
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <Switch.Group as='div' className='flex items-center justify-between'>
      <span className='flex flex-grow flex-col'>
        <Switch.Label as='span' className='text-sm font-medium text-gray-900' passive>
          {description}
        </Switch.Label>
      </span>
      <Switch
        checked={enabled}
        value={selected ? 'charset' : ''}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};
