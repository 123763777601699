import { Combobox } from '@headlessui/react';
import { ArrowPathIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';
import { Employee, useEmployeesQuery } from '../../generated/graphql';
import { employeeFullname, filterEmployees, sortByLastName } from '../../models/EmployeeTypes';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export type EmployeeSearchProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
};

export const EmployeeSearch = <TFormValues extends FieldValues>({
  label,
  className,
  name,
  register,
  control,
  rules,
  errors
}: EmployeeSearchProps<TFormValues>) => {
  const [filter, setFilter] = useState<string>('');

  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPerson, setSelectedPerson] = useState(null);

  const { data, loading } = useEmployeesQuery();
  const people = (data?.employee || []) as Employee[];

  const filteredPeople = sortByLastName(filterEmployees(people, filter));

  return (
    <Controller
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <Combobox as='div' value={value} onChange={onChange}>
            <Combobox.Label className='block text-sm font-medium text-gray-700'>
              {label}
            </Combobox.Label>
            <div className='relative mt-1'>
              <Combobox.Input
                disabled={loading}
                className='w-full text-gray-600 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
                onChange={(event) => setFilter(event.target.value)}
                displayValue={(person: Employee) => (loading ? '' : employeeFullname(person))}
              />
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                {loading ? (
                  <ArrowPathIcon
                    className='h-5 w-5 text-gray-400 animate-spin'
                    aria-hidden='true'
                  />
                ) : (
                  <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                )}
              </Combobox.Button>

              {filteredPeople.length > 0 && (
                <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {filteredPeople.map((person) => (
                    <Combobox.Option
                      key={person.id}
                      value={person}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames('block truncate', selected && 'font-semibold')}
                          >
                            {employeeFullname(person)}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                              )}
                            >
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        );
      }}
      control={control}
      name={name}
    />
  );
};
