import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export type ToggleValue = {
  id: any;
  label: string;
  selected: boolean;
};

type ToggleProps = {
  value: ToggleValue;
  onChange: (value: ToggleValue) => void;
};

export default function Toggle({ value, onChange }: ToggleProps) {
  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch
        checked={value.selected}
        onChange={() => {
          onChange(Object.assign(value, { selected: !value.selected }));
        }}
        className={classNames(
          value.selected ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            value.selected ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as='span' className='ml-3 text-sm'>
        <span className='font-medium text-gray-900'>{value.label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}
