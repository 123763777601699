import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { TextInput } from '../../components/Inputs/TextInput';
import { ToggleFormInput } from '../../components/Inputs/ToggleFormInput';
import {
  Customers,
  useAddCustomerMutation,
  useUpdateCustomerMutation
} from '../../generated/graphql';
import { Api } from '../../providers/Api';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

import { ImageUpload } from '../../components/ImageUpload/ImageUpload';
import { generateCustomerLogoPath } from '../../models/ProjectTypes';
import { isValidBusinessId } from '../../utils/validators';

type CustomerAddFormProps = {
  onComplete: () => void;
};

export const CustomerAddForm = ({ onComplete }: CustomerAddFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [image, setImage] = useState<Blob>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Customers>({
    shouldUnregister: true
  });

  const [addCustomer] = useAddCustomerMutation({
    onError: (err: any) => {
      console.error('useAddCustomerMutation::err', err);
    },
    onCompleted: async (resp: any) => {
      const custId = resp.insert_customers.returning[0].id;

      const logoPath = generateCustomerLogoPath(custId);
      try {
        await Api.uploadImage(logoPath, image!);
        updateCustomer({ variables: { id: custId, data: { logo: logoPath } } });
      } catch (error) {
        console.error('Could update customer image', error);
      }
    }
  });

  const [updateCustomer] = useUpdateCustomerMutation({
    onError: (err: any) => {
      console.error(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onFormSubmit = (data: any) => {
    const cust = {
      name: data.name,
      is_own: (data.is_own || false) && true,
      contact: { name: data.contact }
    };
    setInProgress(true);
    addCustomer({ variables: { ...cust } });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className='p-6 space-y-6'>
      <TextInput<any>
        name='name'
        register={register}
        label={'Customer name'}
        type={'string'}
        rules={{ required: true }}
        errors={errors}
      />
      <TextInput<any>
        name='business_id'
        register={register}
        label={'Business ID'}
        type={'string'}
        rules={{
          required: false,
          validate: {
            checkBusinessId: (value) => isValidBusinessId(value) || 'Invalid business ID'
          }
        }}
        errors={errors}
      />
      <ImageUpload onSelected={(file) => setImage(file)} />
      <ToggleFormInput<any>
        label={'Our company'}
        name='is_own'
        register={register}
        rules={{ required: false }}
        errors={errors}
        control={control}
      />
      <TextInput<any>
        name='contact'
        register={register}
        multiline={true}
        label={'Contact person'}
        type={'string'}
        errors={errors}
      />
      <SideDrawerSubmitButtons submitDisabled={image === undefined} inProgress={inProgress} />
    </form>
  );
};
