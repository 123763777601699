import { useEffect, useState } from 'react';
import { useFocus } from '../Utils/useFocus';

type SearchProps = {
  onFilterUpdated?: (filter: string) => void;
  placeholder?: string;
};

export const Search = ({ onFilterUpdated, placeholder }: SearchProps) => {
  const [searchInput, focus, blur] = useFocus();
  const [filter, setFilter] = useState<string>('');

  const onKeyDown = (e: any) => {
    if (e.metaKey && e.which === 75) {
      focus();
    }

    if (e.which === 27) {
      if (onFilterUpdated) {
        onFilterUpdated('');
      }
      blur();
      setFilter('');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <div className='sm:flex justify-end flex-2'>
      <div className='relative mt-1'>
        <input
          ref={searchInput}
          value={filter}
          onChange={(event) => {
            if (onFilterUpdated) {
              setFilter(event.target.value);
              onFilterUpdated(event.target.value);
            }
          }}
          type='text'
          name='search'
          id='search'
          placeholder={`Search ${placeholder}`}
          className='block w-80 h-10 p-4 rounded-md pr-12 border border-gray-300 focus:border-magenta-300 focus:ring-indigo-300 hover:shadow-gray-500/40'
        />
        <div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5 h-10'>
          <kbd className='inline-flex items-center align-middle justify-items-center self-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400'>
            ⌘ K
          </kbd>
        </div>
      </div>
    </div>
  );
};
