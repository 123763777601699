import { Assignments, Projects, useAssignmentDetailsQuery } from '../../generated/graphql';
import { firstDayOfMonthAsISO, lastDayOfMonthAsISO } from '../../utils/formatters';

import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';

import {
  currencyColumnGenerator,
  employeeAvatarFullnameGenerator,
  hoursColumnGenerator,
  numberColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { MonthContext } from '../../providers/MonthContext';

type ProjectOverviewProps = {
  project: Projects;
};

export const ProjectOverview = ({ project }: ProjectOverviewProps) => {
  const { selectedMonth } = useContext(MonthContext);

  const { data, loading } = useAssignmentDetailsQuery({
    variables: {
      where: {
        project_id: { _eq: project.id }
      },
      time_entries_aggr_where: {
        _and: [
          {
            day: {
              _gte: firstDayOfMonthAsISO(selectedMonth),
              _lte: lastDayOfMonthAsISO(selectedMonth)
            }
          }
        ]
      }
    }
  });

  const columnHelper = createColumnHelper<Assignments>();
  const columns = [
    columnHelper.accessor((row) => row.employee, {
      id: 'employee',
      ...employeeAvatarFullnameGenerator()
    }),
    columnHelper.accessor((row) => row.allocation, {
      id: 'allocation',
      ...numberColumnGenerator('Allocation (%)')
    }),
    columnHelper.accessor((row) => row.time_entries_aggregate.aggregate?.sum?.hours || 0, {
      id: 'hours',
      ...hoursColumnGenerator('Reported hours (h)')
    }),
    columnHelper.accessor(
      (row) => row.external_price * row.time_entries_aggregate.aggregate?.sum?.hours || 0,
      {
        id: 'billing',
        ...currencyColumnGenerator('Billing (€)')
      }
    )
  ];

  const assignments = (data?.assignments || []) as Assignments[];

  return (
    <>
      <div className='mt-16'>
        <ExtendableTable
          title='Monthly Totals'
          columns={columns}
          data={assignments}
          loading={loading}
          initialSort={{
            id: 'employee',
            desc: false
          }}
        />
      </div>
    </>
  );
};
