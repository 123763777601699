import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SideDrawer } from '../../components/Drawers/SideDrawer';
import { DateInput } from '../../components/Inputs/DateInput';
import { TextInput } from '../../components/Inputs/TextInput';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Content, PanelTab } from '../../components/Tabs/Panels';
import {
  Paid_Holidays,
  useAddPaidHolidayMutation,
  usePaidHolidaysQuery
} from '../../generated/graphql';

export const PaidHolidays = () => {
  const { data, loading, refetch } = usePaidHolidaysQuery();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Paid_Holidays>({
    shouldUnregister: true
  });
  const [addPaidHoliday] = useAddPaidHolidayMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      togglePanel();
      console.log(_resp);
      refetch();
    }
  });

  const holidays = (data?.paid_holidays || []) as Paid_Holidays[];
  const togglePanel = () => setOpen(!open);
  const onSubmit = (data: any) => {
    console.log('onSubmit', data);
    addPaidHoliday({
      variables: {
        day: data.day.startDate,
        comment: data.comment
      }
    });
  };

  const columns = [
    {
      accessorKey: 'day',
      header: 'Day'
    },
    {
      accessorKey: 'comment',
      header: 'Comment'
    }
  ];

  return (
    <>
      <PanelTab
        action={
          <button
            type='button'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-royal-dark-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-royal-blue/90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            onClick={togglePanel}
          >
            <PlusIcon className='mr-5 h-6 w-6' aria-hidden='true' />
            Add new
          </button>
        }
      >
        Paid holidays
      </PanelTab>
      <Content>
        <ExtendableTable columns={columns} data={holidays} loading={loading} />
      </Content>
      <SideDrawer
        title='Add new paid holiday'
        description={`Check the title`}
        isOpen={open}
        onClose={togglePanel}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-1 flex-col justify-between'>
          <div className='px-4 py-4 sm:px-6'>
            <DateInput<Paid_Holidays>
              name='day'
              register={register}
              label={'Day'}
              className='space-y-1 pt-1 pb-2'
              rules={{ required: true }}
              errors={errors}
              control={control}
            />
            <TextInput<Paid_Holidays>
              name='comment'
              register={register}
              label={'Comment'}
              className='space-y-1 pt-1 pb-2'
              rules={{ required: true }}
              errors={errors}
            />
          </div>
          <div className='flex flex-shrink-0 justify-end px-4 py-4'>
            <button
              type='button'
              className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={togglePanel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-royal-dark-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-500'
            >
              Save
            </button>
          </div>
        </form>
      </SideDrawer>
    </>
  );
};
