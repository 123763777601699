import { lastDayOfMonth } from 'date-fns';
import { useMemo, useState } from 'react';
import { Select, SelectValue } from '../../components/Inputs/Select';

export const months = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' }
];

type MonthSelectProps = {
  onMonthChange: (month: Date) => void;
  initialValue?: number;
};

export const MonthSelect = ({ onMonthChange, initialValue }: MonthSelectProps) => {
  return (
    <Select
      name='month-selection'
      values={months}
      initialValue={initialValue}
      onChange={(_: string, selected: SelectValue) => {
        const now = new Date();
        onMonthChange(lastDayOfMonth(now.setMonth(months[selected.value].value)));
      }}
    />
  );
};

export type MonthSelectorResult = {
  selectedMonth: Date;
  item: JSX.Element;
};
export const useMonthSelector = () => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());

  const MonthSelectorComponent = useMemo(() => {
    const MSComponent: React.FC = () => {
      return (
        <MonthSelect onMonthChange={setSelectedMonth} initialValue={selectedMonth.getMonth()} />
      );
    };
    return MSComponent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    MonthSelectorComponent,
    selectedMonth
  };
};
