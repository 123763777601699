import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

type SideDrawerSubmitButtonsProps = {
  submitDisabled: boolean;
  inProgress: boolean;
  submitLabel: string;
};

const defaultProps = {
  submitDisabled: false,
  inProgress: false,
  submitLabel: 'Save'
};

export const SideDrawerSubmitButtons = (props: SideDrawerSubmitButtonsProps) => {
  const { dispatch } = useContext(SystemUiContext);
  return (
    <div className='flex flex-shrink-0 justify-end px-4 py-4'>
      <button
        type='button'
        className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm w-32 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        onClick={() => {
          dispatch?.({
            type: SystemUiActions.SHOW_DRAWER,
            payload: {
              open: false,
              content: null
            }
          });
        }}
      >
        Cancel
      </button>

      <button
        type='submit'
        disabled={props.submitDisabled}
        className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-royal-dark-blue py-2 px-4 text-sm w-32 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-500'
      >
        {props.inProgress ? (
          <ArrowPathIcon className='h-6 w-6 text-gray-400 animate-spin' aria-hidden='true' />
        ) : (
          props.submitLabel
        )}
      </button>
    </div>
  );
};

SideDrawerSubmitButtons.defaultProps = defaultProps;
