import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { cropImage } from './ImageUtils';

type ImageUploadProps = {
  onSelected: (image: Blob) => void;
};

export const ImageUpload = ({ onSelected }: ImageUploadProps) => {
  const [image, setImage] = useState<ImageType>();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [zoom, setZoom] = useState(1);

  const onChange = async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    if (imageList.length === 1) {
      setImage(imageList[0]);
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  useEffect(() => {
    if (image && croppedArea) {
      cropImage(image?.dataURL!, croppedArea, image?.file?.type!).then((cropped) => {
        onSelected(cropped);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, croppedArea]);

  return (
    <>
      <label className='inline-block mb-2 text-gray-500'>Customer logo</label>
      {image != null ? (
        <div className='relative w-full h-full'>
          <Cropper
            image={image.dataURL}
            crop={crop}
            zoom={zoom}
            aspect={1}
            minZoom={0.1}
            maxZoom={5}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape='round'
            objectFit='contain'
          />
        </div>
      ) : (
        <ImageUploading
          value={image != null ? [image] : []}
          onChange={onChange}
          maxNumber={1}
          acceptType={['jpg', 'png', 'gif', 'jpeg']}
          maxFileSize={3 * 1024 * 1024}
        >
          {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
            <div>
              <div className='flex items-center justify-center'>
                <label className='flex flex-col p-10 border-2 border-blue-200 border-dashed  hover:bg-gray-100 hover:border-gray-300'>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (imageList.length > 0) {
                        onImageRemove(0);
                      }
                      onImageUpload();
                    }}
                    {...dragProps}
                    className='flex flex-col items-center justify-center flex-grow'
                  >
                    <>
                      <CloudArrowUpIcon className='w-12 h-12' />
                      <p className='text-sm tracking-wider text-center text-gray-400 group-hover:text-gray-600'>
                        Attach an image (png, jpeg, gif)
                      </p>
                    </>
                  </div>
                </label>
              </div>
              {}
            </div>
          )}
        </ImageUploading>
      )}
    </>
  );
};
