import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

export interface SideDrawerProps {
  title: string;
  description: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose(): void;
}

export const SideDrawer = ({ title, description, isOpen, onClose, children }: SideDrawerProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl'>
                  <div className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl overflow-scroll'>
                    <div className='flex flex-1 flex-col justify-between'>
                      <div className='bg-royal-dark-blue py-6 px-4 sm:px-6'>
                        <div className='flex items-center justify-between'>
                          <Dialog.Title className='text-lg font-medium text-white'>
                            {title}
                          </Dialog.Title>
                          <div className='ml-3 flex h-7 items-center'>
                            <button type='button' className='' onClick={onClose}>
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                            </button>
                          </div>
                        </div>
                        <div className='mt-1'>
                          <p className='text-sm text-indigo-300'>{description}</p>
                        </div>
                      </div>
                      <div className='flex flex-1 flex-col justify-between'>{children}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
