import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SideDrawerSubmitButtons } from '../../components/Drawers/SideDrawerSubmitButtons';
import { TextInput } from '../../components/Inputs/TextInput';
import { Spinner } from '../../components/Spinner/Spinner';

import {
  ProjectAggregatedTimeEntresQuery,
  Projects,
  useDeleteProjectMutation,
  useProjectAggregatedTimeEntresQuery
} from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import DefinedRoutes from '../../routes/DefinedRoutes';

type ProjectDeleteFormProps = {
  project: Projects;
  onComplete: () => void;
};
export const ProjectDeleteForm = ({ project, onComplete }: ProjectDeleteFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Projects>({
    shouldUnregister: true
  });

  const { data, loading } = useProjectAggregatedTimeEntresQuery({
    variables: {
      id: project.id
    }
  });

  const [deleteProject] = useDeleteProjectMutation({
    onError: (err: any) => {
      console.log(err);
      setInProgress(false);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
      navigate(DefinedRoutes.PROJECTS);
    }
  });

  const onFormSubmit = (data: any) => {
    setInProgress(true);
    deleteProject({
      variables: {
        id: project.id
      }
    });
  };

  const hasEntries = (data: ProjectAggregatedTimeEntresQuery | undefined) => {
    if (data === undefined) {
      return true;
    }
    const ss = data.projects_aggregate.nodes.find((a) => {
      return a.assignments_aggregate.nodes.find((t) => {
        return t.time_entries_aggregate.aggregate?.sum?.hours > 0;
      });
    });

    return ss !== undefined;
  };

  if (loading) {
    return (
      <div className='flex justify-center mt-20'>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className='flex-col p-6 space-y-6'>
        {hasEntries(data) && (
          <span className='text-center text-red-500 font-medium mb-10'>
            Project cannot be deleted as it contasin time entries
          </span>
        )}
        <form onSubmit={handleSubmit(onFormSubmit)} className='space-y-6'>
          <TextInput<any>
            name='name'
            register={register}
            label={'Project name to delete (type project name for as confirmation)'}
            type={'string'}
            rules={{ validate: (value) => value === project?.name && !hasEntries(data) }}
            errors={errors}
            placeholder={project?.name || ''}
          />

          <SideDrawerSubmitButtons
            inProgress={inProgress}
            submitDisabled={!isValid}
            submitLabel='Delete'
          />
        </form>
      </div>
    );
  }
};
