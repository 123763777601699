import { endOfMonth, isSameDay, isWeekend, startOfMonth } from 'date-fns';

export const workingDaysInMonth = (month: Date, holidays: Date[]): number => {
  return workingDaysBetween(startOfMonth(month), endOfMonth(month), holidays);
};

export const workingDaysBetween = (start: Date, end: Date, holidays: Date[]): number => {
  var wd = 0;

  for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    if (!(isWeekend(d) || datesIncludes(d, holidays))) {
      wd += 1;
    }
  }
  return wd;
};

export const datesIncludes = (item: Date, array: Date[]): boolean => {
  return array.find((d) => isSameDay(d, item)) !== undefined;
};

export const toArray = (start: Date, end: Date): Date[] => {
  const days: Date[] = [];
  for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    days.push(new Date(d));
  }
  return days;
};

export const toMonthArray = (month: Date): Date[] => {
  return toArray(startOfMonth(month), endOfMonth(month));
};

export const firstDayOfMonth = (value: Date): Date => {
  return new Date(value.getFullYear(), value.getMonth(), 1);
};
