import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export type ListBoxValue<TFormValues> = {
  id: any;
  label: string;
  value: any;
} & PathValue<TFormValues, Path<TFormValues>>;

export type ListBoxProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  values: ListBoxValue<TFormValues>[];
};

export const ListBox = <TFormValues extends FieldValues>({
  label,
  className,
  name,
  register,
  control,
  rules,
  errors,
  values
}: ListBoxProps<TFormValues>) => {
  return (
    <Controller
      rules={rules}
      defaultValue={values[0]}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={className}>
            <Listbox value={value} onChange={onChange}>
              {({ open }) => (
                <>
                  <Listbox.Label className='block font-medium leading-6 text-gray-600'>
                    {label} {errors?.[name] && <span> - This field is required</span>}
                  </Listbox.Label>
                  <div className='relative mt-2'>
                    <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                      <span className='block truncate'>{value?.label}</span>
                      <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                        <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave='transition ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                        {values.map((v) => (
                          <Listbox.Option
                            key={v.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={v}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {v.label}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        );
      }}
      control={control}
      name={name}
    />
  );
};
