interface StatsCardProps {
  label: string;
  value: string;
}

export const StatsCard = ({ label, value }: StatsCardProps): JSX.Element => {
  return (
    <div className='bg-light-gray py-4 px-6 shadow rounded flex flex-col'>
      <span className='text-sm mb-1'>{label}</span>
      <span className='text-3xl font-bold'>{value}</span>
    </div>
  );
};
