interface GridItem2Props {
  left?: JSX.Element;
  center?: JSX.Element;
  right?: JSX.Element | null;
}

// required non-null key prop
interface WithKeyProps {
  key: React.Key;
}

export const GridItem2 = ({ left, center, right, ...rest }: GridItem2Props & WithKeyProps) => {
  return (
    <div
      {...rest}
      className='relative flex justify-between items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-gray-400'
    >
      {left}
      {center}
      {right}
    </div>
  );
};
