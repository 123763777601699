import { ArrowPathIcon, CloudArrowUpIcon, LinkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { formatISO, lastDayOfMonth } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { StatsCard } from '../components/Cards/StatsCard';
import { ModalDialog } from '../components/Modals/ModalDialog';
import { EmptyPageLayout } from '../components/PageLayout/EmptyPageLayout';
import { Search } from '../components/Search/Search';
import {
  PercentageColumn,
  employeeAvatarFullnameGenerator,
  hoursColumnGenerator
} from '../components/Tables/ColumnTypes';
import { ExtendableTable } from '../components/Tables/ExtendableTable';
import {
  Employee,
  useCalendarQuery,
  useCompanyMonthlyBillingByProjectAndEmployeeQuery,
  useMonthlyHoursReportQuery
} from '../generated/graphql';
import {
  activeInMonth,
  billableAndActiveInMonth,
  filterEmployees,
  sortByLastName
} from '../models/EmployeeTypes';
import { useGoogleSheetExport } from '../providers/GoogleSpreadSheet';
import { MonthContext } from '../providers/MonthContext';
import {
  firstDayOfMonthAsISO,
  formatCurrency,
  lastDayOfMonthAsISO,
  monthName,
  toDecimalString
} from '../utils/formatters';
import { createMonthlyBillingSummarySheet } from '../utils/spread_sheet';

export const Dashboard = () => {
  const [filter, setFilter] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { selectedMonth, workingDays } = useContext(MonthContext);

  const [exporting, exportSheet, sheetId] = useGoogleSheetExport();
  const [nonBillableSelector, setNonBillableSelector] = useState(0);

  useEffect(() => {
    setModalOpen(sheetId != null);
  }, [sheetId]);

  const { loading, data } = useMonthlyHoursReportQuery({
    variables: {
      report_month: lastDayOfMonthAsISO(selectedMonth)
    }
  });

  const calendarData = useCalendarQuery({
    variables: { from: firstDayOfMonthAsISO(selectedMonth), to: lastDayOfMonthAsISO(selectedMonth) }
  });

  const calendar = calendarData.data?.calendar || [];

  const billableDays = calendar.reduce((prev, curr) => {
    const wd = curr.weekday || 0;
    const hd = curr.paid_holiday || 0;
    return prev + wd - hd * wd;
  }, 0);

  const calculateSickDays = (employees: Employee[]): number => {
    return Math.ceil(
      employees.reduce((sickDays, employee): number => {
        return sickDays + (employee?.monthly_hours_report[0]?.sickleave_hours || 0);
      }, 0) / 7.5
    );
  };

  const d = (data?.employee || []) as Employee[];
  const employeeData =
    nonBillableSelector === 0
      ? billableAndActiveInMonth(d, selectedMonth)
      : activeInMonth(d, selectedMonth);

  const actualizedBilling =
    data?.employee_monthly_billing_report_aggregate?.aggregate?.sum?.external_billing || 0;

  const maxBillableHours = employeeData.length * billableDays * 7.5;
  const actualizedBillableHours = employeeData.reduce((prev, curr) => {
    return prev + (curr.monthly_hours_report[0]?.billable_hours || 0);
  }, 0);
  const billingPercent =
    maxBillableHours > 0 ? (actualizedBillableHours / maxBillableHours) * 100 : 0;

  const transformedData = sortByLastName(filterEmployees(employeeData, filter));

  const onFilterUpdated = (value: string) => setFilter(value);

  const columnHelper = createColumnHelper<Employee>();

  const columns = [
    columnHelper.accessor((row) => row, {
      id: 'employee',
      ...employeeAvatarFullnameGenerator()
    }),
    columnHelper.accessor((row) => row.monthly_hours_report[0]?.billable_hours, {
      id: 'billable_hours',
      ...hoursColumnGenerator('Billable hours')
    }),
    columnHelper.accessor((row) => row.monthly_hours_report[0]?.company_hours, {
      id: 'company_hours',
      ...hoursColumnGenerator('Company hours')
    }),
    columnHelper.accessor((row) => row.monthly_hours_report[0]?.sickleave_hours, {
      id: 'sickleave_hours',
      ...hoursColumnGenerator('Sickleave hours')
    }),
    columnHelper.accessor((row) => row.monthly_hours_report[0]?.holiday_hours, {
      id: 'holiday_hours',
      ...hoursColumnGenerator('Holidays hours')
    }),

    {
      id: 'billing_percent',
      header: () => <div className='text-end'>Billing %</div>,
      cell: (item: any) => {
        const employee = item.row.original as Employee;
        const h = employee.monthly_hours_report[0]?.billable_hours || 0;
        const p = (h / (billableDays * 7.5)) * 100;
        return <PercentageColumn value={p} />;
      },
      size: 80
    }
  ];

  const buttonLogo = () => {
    if (exporting) {
      return <ArrowPathIcon className='mr-5 h-6 w-6 text-white animate-spin' aria-hidden='true' />;
    } else {
      return <CloudArrowUpIcon className='mr-5 h-6 w-6' />;
    }
  };

  const { data: billing } = useCompanyMonthlyBillingByProjectAndEmployeeQuery({
    variables: {
      report_month: lastDayOfMonthAsISO(selectedMonth)
    }
  });

  const exportButton = () => {
    return (
      <button
        type='button'
        className='ml-4 inline-flex items-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-gold shadow-sm hover:bg-royal-dark-blue focus:outline-none '
        onClick={() => {
          const values = createMonthlyBillingSummarySheet(billing?.company_monthly_billing_report);

          const payrollMonthString = formatISO(lastDayOfMonth(selectedMonth), {
            format: 'extended',
            representation: 'date'
          });
          const exportDateString = formatISO(new Date());
          exportSheet(`${payrollMonthString}-Billing (Exported on ${exportDateString})`, values);
        }}
      >
        {buttonLogo()}
        Export to Google sheets
      </button>
    );
  };

  type TagProps = {
    label: string;
    index: number;
  };
  const Tag = ({ label, index }: TagProps) => {
    const bg =
      nonBillableSelector === index ? 'bg-royal-blue text-white' : 'bg-transparent text-black';

    return (
      <div
        className={`flex align-middle ${bg} border-royal-blue border pt-1 pb-1 pl-2 pr-2 rounded-full mr-2 cursor-pointer`}
        onClick={() => setNonBillableSelector(index)}
      >
        <span className='mr-3 ml-3'>{label}</span>
      </div>
    );
  };

  const billableSelector = () => {
    return (
      <div className='flex'>
        <Tag label={'Billable employees'} index={0} />
        <Tag label={'All employees'} index={1} />
      </div>
    );
  };

  return (
    <>
      <EmptyPageLayout title='Dashboard' rightComponent={exportButton()}>
        <div className='flex flex-shrink-0 justify-between'>
          <div className='flex-col'>
            <div className='w-96 text-2xl font-normal'>{`${monthName(
              selectedMonth
            )} ${selectedMonth.getFullYear()}`}</div>
            <span className='text-sm'>{`${workingDays} working days`}</span>
          </div>
          <Search onFilterUpdated={onFilterUpdated} placeholder={'people'} />
        </div>

        <div className='grid grid-cols-3 gap-8 mb-12 mt-12'>
          <StatsCard label='Actualized billing' value={`${formatCurrency(actualizedBilling)}`} />
          <StatsCard label='Billing %' value={toDecimalString(billingPercent, 2)} />
          <StatsCard label='Sick days' value={calculateSickDays(employeeData).toString()} />
        </div>

        <ExtendableTable
          columns={columns}
          data={transformedData}
          loading={loading}
          title='Employee summary'
          action={billableSelector()}
        />
      </EmptyPageLayout>
      <ModalDialog
        isOpen={modalOpen}
        title='Open'
        description={`Do you want the open the newly exported billing data in google sheets?`}
        cancelLabel={'Cancel'}
        actionLabel={'Open'}
        icon={<LinkIcon className='text-gold' />}
        onClose={() => {
          setModalOpen(false);
        }}
        onAction={() => {
          window.open('https://docs.google.com/spreadsheets/d/' + sheetId, '_blank', 'noreferrer');
          setModalOpen(false);
        }}
      />
    </>
  );
};
