import { createColumnHelper } from '@tanstack/react-table';
import {
  assignmentProjectNameAvatarGenerator,
  dateColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Assignments } from '../../generated/graphql';

interface EmployeeDetailsProjectsProps {
  assignments: Assignments[];
  onReloadNeeded: () => void;
}

export const EmployeeProjects = ({ assignments, onReloadNeeded }: EmployeeDetailsProjectsProps) => {
  const columnHelper = createColumnHelper<Assignments>();
  const columns = [
    columnHelper.accessor((row) => row, {
      id: 'project',
      ...assignmentProjectNameAvatarGenerator()
    }),
    columnHelper.accessor((row) => row.start_date, {
      id: 'start_date',
      ...dateColumnGenerator('Start date')
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: 'end_date',
      ...dateColumnGenerator('End date')
    })
  ];

  return (
    <div>
      <div className='mb-12'>
        <ExtendableTable columns={columns} data={assignments} title='Assigned projects' />
      </div>
    </div>
  );
};
