/* eslint-disable @typescript-eslint/no-unused-vars */

import { gql } from '@apollo/client';

export const ADD_EMPLOYEE = gql`
  mutation AddEmployee($first_name: String, $last_name: String, $email: String, $avatar: String) {
    insert_employee(
      objects: { first_name: $first_name, last_name: $last_name, email: $email, avatar: $avatar }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: uuid, $data: employee_set_input) {
    update_employee(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ASSIGNMENT = gql`
  mutation UpdateAssignment($id: uuid, $data: assignments_set_input) {
    update_assignments(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_PAID_HOLIDAY = gql`
  mutation AddPaidHoliday($day: date, $comment: String) {
    insert_paid_holidays(objects: { day: $day, comment: $comment }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_ASSIGNMENT_TO_PROJECT = gql`
  mutation AddAssignmentToProject(
    $employee_id: uuid
    $start_date: date
    $end_date: date
    $allocation: numeric
    $external_price: numeric
    $internal_price: numeric
    $comment: String
    $project_id: uuid
    $po: String
  ) {
    insert_assignments(
      objects: {
        employee_id: $employee_id
        project_id: $project_id
        start_date: $start_date
        end_date: $end_date
        allocation: $allocation
        external_price: $external_price
        internal_price: $internal_price
        comment: $comment
        po: $po
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const DELETE_ASSIGNMENT = gql`
  mutation DeleteAssignment($id: uuid) {
    delete_assignments(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_PROJECT = gql`
  mutation AddProject(
    $customer_id: uuid
    $name: String
    $start_date: date
    $end_date: date
    $type: String
    $po: String
  ) {
    insert_projects(
      objects: {
        customer_id: $customer_id
        name: $name
        start_date: $start_date
        end_date: $end_date
        type: $type
        po: $po
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: uuid, $data: projects_set_input) {
    update_projects(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: uuid) {
    delete_projects(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation AddCustomer($name: String, $logo: String, $is_own: Boolean, $contact: json) {
    insert_customers(objects: { name: $name, logo: $logo, is_own: $is_own, contact: $contact }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: uuid, $data: customers_set_input) {
    update_customers(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const CREATE_EMPLOYEE_ALLOCATION = gql`
  mutation AddEmployeeAllocation($data: [employee_allocations_insert_input!]!) {
    insert_employee_allocations(objects: $data) {
      returning {
        id
      }
    }
  }
`;

const CREATE_BALANCE_CORRECTION = gql`
  mutation AddBalanceCorrection($data: [balance_corrections_insert_input!]!) {
    insert_balance_corrections(objects: $data) {
      returning {
        id
      }
    }
  }
`;

const DELETE_EMPLOYEE_ALLOCATION = gql`
  mutation DeleteEmployeeAllocation($id: uuid) {
    delete_employee_allocations(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const DELETE_EMPLOYEE_BC = gql`
  mutation DeleteBalanceCorrection($id: uuid) {
    delete_balance_corrections(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const CREATE_PAYROLL_SETTINGS = gql`
  mutation AddSalaryCoeffs($data: [salary_coeffs_insert_input!]!) {
    insert_salary_coeffs(objects: $data) {
      returning {
        base_salary
        bonus_percentage
        company_side_cost_coef
        day_wage
        hourly_wage
        period
      }
    }
  }
`;
