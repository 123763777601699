import { endOfMonth, isAfter, isBefore, isSameDay, startOfMonth } from 'date-fns';
import { Assignments, Customers, Projects, Time_Entries } from '../generated/graphql';

export interface ListComponent {
  [key: string]: any; // type for unknown keys.
  comp: React.ElementType;
}

export const filterCustomersProjects = (customers: Customers[], filter: string): Customers[] => {
  return customers.reduce((prev, current, idx, target) => {
    if (filter.length > 0) {
      const filtered = filterProjects(current.projects, filter);
      if (filtered.length > 0) {
        prev.push({ ...current, projects: filtered });
      }
    } else {
      prev.push({ ...current, projects: current.projects });
    }
    return prev;
  }, [] as Customers[]);
};

export const filterProjects = (projects: Projects[], filter: string): Projects[] => {
  return projects.filter((project) =>
    project.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );
};

export const entriesForCustomer = (
  entries: Time_Entries[],
  customer: Customers
): Time_Entries[] => {
  return entries.filter((entry) => entry.assignment?.project?.customer?.name === customer.name);
};

export const activeProjects = (project: Projects, month?: Date): boolean => {
  const em = endOfMonth(month ?? Date.now());
  const sm = startOfMonth(month ?? Date.now());
  const ps = Date.parse(project.start_date);
  const pe = Date.parse(project.end_date);
  return (isAfter(pe, sm) || isSameDay(pe, sm)) && (isBefore(ps, em) || isSameDay(ps, em));
};

export const closedProjects = (project: Projects): boolean => {
  return isBefore(Date.parse(project.end_date), Date.now());
};

export type ProjectFilter = (projects: Projects) => boolean;

export const projectsFor = (customers: Customers[], projectFilter: ProjectFilter): Customers[] => {
  return customers.reduce((prev, current, idx, target) => {
    const activeProjects = current.projects.filter((project) => projectFilter(project));
    if (activeProjects.length > 0) {
      prev.push({ ...current, projects: activeProjects });
    }
    return prev;
  }, [] as Customers[]);
};

export const customersFromTimeEntries = (entries: Time_Entries[]): Customers[] => {
  return entries.reduce((prev, current) => {
    const cc = current.assignment?.project?.customer!;
    const exists = prev.find((c) => c.name === cc.name);
    if (exists === undefined) {
      prev.push(cc);
    }
    return prev;
  }, [] as Customers[]);
};

export const customersFromAssignments = (assignments: Assignments[]): Customers[] => {
  return assignments.reduce((prev, current) => {
    prev.push(current.project?.customer!);
    return prev;
  }, [] as Customers[]);
};

export const sortCustomers = (customers: Customers[]) => {
  return [...customers].sort((a, b) => a.name.localeCompare(b.name, 'FI'));
};

export const customerLogoUrl = (customer: Customers | undefined): string => {
  var logoUrl = '';
  if (customer === undefined) {
    return logoUrl;
  }
  if (customer.logo != null && !customer.logo.includes('https')) {
    logoUrl = `${process.env.REACT_APP_API_BASE_URL}images/${customer.logo}`;
  } else {
    logoUrl = customer.logo!;
  }
  return logoUrl;
};

export const generateCustomerLogoPath = (customer: Customers | string) => {
  const time = Date.now();
  if (typeof customer === 'string') {
    return `${customer}-logo-${time}`;
  }
  return `${customer.id}-logo-${time}`;
};
