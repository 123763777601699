import { Navigate, useOutlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar/Navbar';
import { useAuth } from '../providers/Auth';
import DefinedRoutes from './DefinedRoutes';

export const ProtectedLayout = () => {
  const auth = useAuth();
  const outlet = useOutlet();

  if (!auth?.authenticated) {
    return <Navigate to={DefinedRoutes.LOGIN} replace />;
  }

  if (!auth.user.isAdmin()) {
    return <Navigate to={DefinedRoutes.NO_SUPER_POWERS} replace />;
  }

  return (
    <div className='bg-royal-blue'>
      <div className='h-[350px] bg-black'>
        <Navbar />
        {outlet}
      </div>
    </div>
  );
};
