import { Auth } from 'aws-amplify';
import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

export class Api {
  static uploadImage = async (name: string, imageData: Blob) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();

    return http.put(`/images/${name}`, imageData, {
      headers: {
        'Content-Type': imageData.type,
        Authorization: accessToken
      }
    });
  };
}
