import * as d3 from 'd3';

import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { formatISO, isBefore, isSameMonth, lastDayOfMonth, startOfMonth } from 'date-fns';
import { useContext, useMemo } from 'react';
import { useDownloadFile } from 'react-downloadfile-hook';
import { StatsCard } from '../../components/Cards/StatsCard';
import { ProjectBillingChart } from '../../components/Projects/ProjectBillingChart';
import { ProjectMonthlyReportTable } from '../../components/Projects/ProjectMonthlyReportTable';
import { Content, PanelButton, PanelTab } from '../../components/Tabs/Panels';
import { Projects, useCustomerDetailedBillingReportQuery } from '../../generated/graphql';
import { MonthContext } from '../../providers/MonthContext';
import { formatCurrency, formatHours } from '../../utils/formatters';
import { ProjectOverview } from './ProjectOverview';

type ProjectReportProps = {
  project: Projects;
};

export const ProjectReport = ({ project }: ProjectReportProps) => {
  const { data } = useCustomerDetailedBillingReportQuery({
    variables: {
      customer_id: project.customer?.id
    }
  });

  const { selectedMonth } = useContext(MonthContext);

  const transposed = useMemo(() => {
    return (
      data?.assignments
        .reduce((prev: any, current: any) => {
          return [
            ...prev,
            ...current.time_entries.map((e: any) => {
              return {
                day: d3.utcParse('%Y-%m-%d')(e.day),
                project: current.project.name,
                project_id: current.project.id,
                price: current.external_price,
                hours: e.hours as number,
                billing: e.hours * current.external_price,
                employee: `${current.employee.last_name} ${current.employee.first_name}`,
                employee_id: current.employee.id,
                avatar: current.employee.avatar
              };
            })
          ];
        }, [])
        .sort((a: any, b: any) => (isBefore(a.day, b.day) ? -1 : 1))
        .filter((a: any) => a.project_id === project.id && isSameMonth(a.day, selectedMonth)) || []
    );
  }, [data, selectedMonth, project]);

  const blob = useMemo(() => {
    const monthExtent = [startOfMonth(selectedMonth), lastDayOfMonth(selectedMonth)];
    const allDays: any[] = d3.timeDays(monthExtent[0], monthExtent[1]);

    const employees = d3.group(transposed, (d: any) => d.employee);
    const byDayAndEmployee = d3.group(
      transposed,
      (d: any) => formatISO(d.day, { representation: 'date' }),
      (d: any) => d.employee
    );

    const csv = [['Day', ...employees.keys()]];

    allDays.reduce((prev, curr) => {
      const formattedDate = formatISO(curr, { representation: 'date' });
      const row: any[] = [formattedDate];
      [...employees.keys()].forEach((e) => {
        const dayValueForEmployee = byDayAndEmployee.get(formattedDate)?.get(e);
        const hours =
          dayValueForEmployee !== undefined && dayValueForEmployee.length > 0
            ? dayValueForEmployee[0].hours
            : 0;
        row.push(hours);
      });
      prev.push(row);
      return prev;
    }, csv);

    const d = d3.csvFormatRows(csv);
    const blob = new Blob([d], { type: 'text/csv;charset=utf-8,' });
    return blob;
  }, [transposed, selectedMonth]);

  const totalBilling = d3.sum(transposed, (d: any) => d.billing);
  const totalHours = d3.sum(transposed, (d: any) => d.hours);
  const peeps = [...d3.group(transposed, (d) => d.employee).keys()].length;

  const { downloadFile } = useDownloadFile({
    fileName: `${project.name}-report-${formatISO(startOfMonth(selectedMonth), {
      representation: 'date'
    })}- ${formatISO(lastDayOfMonth(selectedMonth), {
      representation: 'date'
    })}`,
    format: 'text/csv;charset=utf-8',
    data: blob
  });

  const downloadCsv = () => {
    return (
      <PanelButton
        label='Download'
        onClick={() => {
          downloadFile();
        }}
        icon={<CloudArrowDownIcon className='h-5 w-5 mr-5' />}
      />
    );
  };

  return (
    <>
      <PanelTab action={downloadCsv()}>Report</PanelTab>
      <Content>
        {transposed.length > 0 ? (
          <>
            <div className='grid grid-cols-3 gap-8 mb-12 mt-12'>
              <StatsCard label='Billing' value={`${formatCurrency(totalBilling)}`} />
              <StatsCard label='Effort' value={`${formatHours(totalHours)} h`} />
              <StatsCard label='People' value={`${peeps}`} />
            </div>

            <ProjectBillingChart transposed={transposed} />
            <ProjectOverview project={project} />
            <ProjectMonthlyReportTable transposed={transposed} />
          </>
        ) : (
          <div className='flex mt-10 center text-lg justify-center'>No time entries yet</div>
        )}
      </Content>
    </>
  );
};
