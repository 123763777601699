import { MutableRefObject, useRef } from 'react';

export const useFocus = (): [any, () => void, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null);
  const setFocus = (): void => {
    htmlElRef?.current?.focus?.();
  };

  const blur = (): void => {
    htmlElRef?.current?.blur?.();
  };

  return [htmlElRef, setFocus, blur];
};
