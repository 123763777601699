import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';

export type DateRangeInputProps<TFormValues extends FieldValues> = {
  label: string;
  className?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  register: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  placeHolder?: string;
};

export const DateRangeInput = <TFormValues extends FieldValues>({
  label,
  className,
  name,
  register,
  control,
  rules,
  errors,
  placeHolder
}: DateRangeInputProps<TFormValues>) => {
  const { ref, ...rest } = register(name);
  return (
    <Controller
      rules={rules ?? { required: true }}
      render={({ field: { onChange, onBlur, value } }) => {
        //const v: any = value == null ? { startDate: null, endDate: null } : value;
        return (
          <div aria-live='polite'>
            <label className='text-gray-600 font-medium'>
              {label} {errors?.period && <span> - This field is required</span>}
            </label>
            <Datepicker
              {...rest}
              inputClassName={
                'border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-700'
              }
              separator={'->'}
              primaryColor={'blue'}
              value={value}
              onChange={onChange}
              displayFormat={'DD.MM.YYYY'}
              placeholder={placeHolder}
            />
          </div>
        );
      }}
      control={control}
      name={name}
    />
  );
};
