import { Tooltip } from 'flowbite-react';
import { Employee } from '../../generated/graphql';
import {
  employeeFullname,
  employeeInitials,
  employeeIsNonBillable
} from '../../models/EmployeeTypes';
import { Avatar, AvatarProps } from './Avatar';

type EmployeeAvatarProps = {
  employee: Employee;
  active?: boolean;
} & AvatarProps;

export const EmployeeAvatar = ({
  employee,
  size,
  notification,
  active = true
}: EmployeeAvatarProps) => {
  return (
    <Tooltip content={employeeFullname(employee)}>
      <Avatar
        url={employee.avatar}
        size={size}
        notification={notification}
        borderColor={active || active === undefined ? undefined : 'border-red-700 border-2'}
        className={employeeIsNonBillable(employee) ? 'p-[2px] rounded-full bg-green-500' : ''}
        fallback={employeeInitials(employee)}
      />
    </Tooltip>
  );
};
