import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

export const createApolloClient = (authToken: string) => {
  return new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only'
      }
    },
    link: new HttpLink({
      uri: process.env.REACT_APP_HASURA_URL as string,
      headers: {
        'x-hasura-admin-secret': `${authToken}`
      }
    }),
    cache: new InMemoryCache()
  });
};
