import { useState } from 'react';

type Tab = {
  name: string;
  component: JSX.Element;
  action?: JSX.Element;
};

interface TabsProps {
  items: Tab[];
}

export const Tabs = ({ items }: TabsProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8 last:ml-auto' aria-label='Tabs'>
            {items.map((tab: Tab, index: number) => (
              <span
                key={tab.name}
                onClick={() => setActiveIndex(index)}
                className={classNames(
                  index === activeIndex
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={index === activeIndex ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
            {items[activeIndex].action ? (
              <span className='flex flex-grow align-middle justify-end'>
                {items[activeIndex].action}
              </span>
            ) : null}
          </nav>
        </div>
      </div>
      <div className='mt-12'>{items[activeIndex].component}</div>
    </>
  );
};
