type TitleWithSubtitleProps = {
  title?: string | undefined | null;
  subtitle?: string | undefined | null;
};

export const TitleWithSubtitle = ({ title, subtitle }: TitleWithSubtitleProps) => {
  return (
    <div className='min-w-0 flex-1'>
      <p className='text-sm font-medium text-gray-900'>{title || ''}</p>
      <p className='truncate text-sm text-gray-500'>{subtitle || ''}</p>
    </div>
  );
};
