import { useForwardedRef } from '@bedrock-layout/use-forwarded-ref';
import { createContext, forwardRef, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

type PanelsContextType = {
  setSelectedPanel: (panel: typeof Panel) => void;
  selectedPanel: typeof Panel | undefined;
};
const PanelsContext = createContext<PanelsContextType>({
  setSelectedPanel: () => {},
  selectedPanel: undefined
});

const PanelsTabContext = createContext(null);

const NOOP = () => {};

export const Panels = ({ children, activePanel, onActivePanelChange = NOOP }: any) => {
  const [panelsTabDom, setPanelsTabDom] = useState<any>(null);

  const ctx = {
    selectedPanel: activePanel,
    setSelectedPanel: onActivePanelChange
  };

  return (
    <>
      <div className='relative'>
        <nav
          className=' flex border-b border-gray-200 -mb-px space-x-8 last:ml-auto'
          aria-label='Tabs'
          ref={setPanelsTabDom}
        ></nav>
      </div>

      <PanelsTabContext.Provider value={panelsTabDom}>
        <PanelsContext.Provider value={ctx}>
          {panelsTabDom ? children : null}
        </PanelsContext.Provider>
      </PanelsTabContext.Provider>
    </>
  );
};

const SinglePanelContext = createContext({});

export const Panel = forwardRef(({ children }: any, ref) => {
  const panelRef = useForwardedRef(ref);

  if (!panelRef.current) {
    panelRef.current = {};
  }

  const v: any = panelRef.current;
  return <SinglePanelContext.Provider value={v}>{children}</SinglePanelContext.Provider>;
});

export const PanelTab = ({ children, action }: any) => {
  const tabListElement = useContext<any>(PanelsTabContext);
  const { selectedPanel, setSelectedPanel } = useContext(PanelsContext);
  const panel = useContext<any>(SinglePanelContext);

  const handleClick = useCallback(() => setSelectedPanel(panel), [setSelectedPanel, panel]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  const isSelected = selectedPanel === panel;

  const cls = classNames(
    isSelected
      ? 'border-indigo-500 text-indigo-600'
      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
  );

  return createPortal(
    <>
      <div className={cls} onClick={handleClick}>
        {children}
      </div>
      {isSelected ? <div className='absolute bottom-3 right-0'>{action}</div> : null}
    </>,
    tabListElement
  );
};

export const Content = ({ children }: any) => {
  const { selectedPanel } = useContext(PanelsContext);
  const panel = useContext(SinglePanelContext);

  return selectedPanel === panel ? <div className='pt-5 pb-5'>{children}</div> : null;
};

type PanelButtonProps = {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
};

export const PanelButton = ({ label, icon, onClick }: PanelButtonProps) => {
  return (
    <button
      type='button'
      className='ml-4 inline-flex items-center rounded-md border border-transparent bg-royal-dark-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-500'
      onClick={onClick}
    >
      {icon}
      {label}
    </button>
  );
};
