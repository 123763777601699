import { formatISO } from 'date-fns';
import { createContext, useMemo, useState } from 'react';
import { usePaidHolidaysQuery } from '../generated/graphql';
import { workingDaysInMonth } from '../utils/date';

interface MonthContextType {
  selectedMonth: Date;
  selectedMonthISO: string;
  setSelectedMonth: (month: Date) => void;
  workingDays: number;
}

export const MonthContext = createContext<MonthContextType>({
  selectedMonth: new Date(),
  selectedMonthISO: formatISO(new Date()),
  setSelectedMonth: () => {},
  workingDays: 0
});

type MonthSelectionContextProps = {
  children: JSX.Element | JSX.Element[];
};

export const MonthSelectionProvider = ({ children }: MonthSelectionContextProps) => {
  const [month, setMonth] = useState<Date>(new Date());

  const { data } = usePaidHolidaysQuery();

  const aa = (data?.paid_holidays || []).map((h) => new Date(h.day));
  const wd = workingDaysInMonth(month, aa);
  const rv: MonthContextType = useMemo(
    () => ({
      selectedMonth: month,
      selectedMonthISO: formatISO(month),
      setSelectedMonth: setMonth,
      workingDays: wd
    }),
    [month, setMonth, wd]
  );

  return <MonthContext.Provider value={rv}>{children}</MonthContext.Provider>;
};
