import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Customers, Projects, useUpdateCustomerMutation } from '../../generated/graphql';
import { generateCustomerLogoPath } from '../../models/ProjectTypes';
import { Api } from '../../providers/Api';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { SideDrawerSubmitButtons } from '../Drawers/SideDrawerSubmitButtons';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { TextInput } from '../Inputs/TextInput';
import { isValidBusinessId } from '../../utils/validators';

type ProjectAddFormProps = {
  customer: Customers;
  onComplete: () => void;
};
export const CustomerEditForm = ({ customer, onComplete }: ProjectAddFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [image, setImage] = useState<Blob>();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Projects>({
    shouldUnregister: true
  });

  const [updateCustomer] = useUpdateCustomerMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onFormSubmit = async (data: any) => {
    const logoPath = generateCustomerLogoPath(customer);
    if (image != null) {
      try {
        const logoPath = generateCustomerLogoPath(customer);
        await Api.uploadImage(logoPath, image);
      } catch (error) {
        console.error('Could update customer image', error);
      }
    }
    const values = {
      name: data.name,
      business_id: data.business_id,
      logo: logoPath
    };

    const filtered = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v != null && v !== '')
    );

    if (Object.keys(filtered).length > 0) {
      updateCustomer({
        variables: {
          id: customer.id,
          data: filtered
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className='p-6 space-y-6'>
      <TextInput<any>
        name='name'
        register={register}
        label={'Customer name'}
        type={'string'}
        rules={{ required: false }}
        errors={errors}
        placeholder={customer?.name || ''}
      />
      <TextInput<any>
        name='business_id'
        register={register}
        label={'Business ID'}
        type={'string'}
        rules={{
          required: false,
          validate: {
            checkBusinessId: (value) => isValidBusinessId(value) || 'Invalid business ID'
          }
        }}
        errors={errors}
        placeholder={customer?.business_id || ''}
      />
      <ImageUpload onSelected={(file) => setImage(file)} />
      <SideDrawerSubmitButtons />
    </form>
  );
};
