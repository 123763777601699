type SparkProps = {
  small?: boolean;
};
export const Spark = ({ small = false }: SparkProps) => {
  return (
    <div className={`flex justify-center ${small ? 'h-[1px]' : 'h-[6px]'}`}>
      <div className='flex-1 bg-gradient-to-r from-[#ff3bA3] via-[#990858] to-[#FFE600]' />
      <div className='flex-1 bg-gradient-to-r  from-[#FFE600] via-[#58771E] to-[#3BA3FF]' />
      <div className='flex-1 bg-gradient-to-r  from-[#3BA3FF] to-[#202D85]' />
    </div>
  );
};
// background:linear-gradient(90deg, #FF3BA3 0%, #990858 20.83%, #FFE600 40.1%, #58771E 62.5%, #3BA3FF 81.77%, #202D85 100%)
