const DefinedRoutes = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  PEOPLE: '/people',
  EMPLOYEE: '/people/:id',
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  CUSTOMERS: '/customers',
  CUSTOMER: '/customer/:id',
  CALENDAR: '/calendar',
  PAYROLL: '/payroll',
  NO_SUPER_POWERS: '/no_power'
};

export default DefinedRoutes;
