import { Auth, Hub } from 'aws-amplify';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import AutheticatedUser from '../models/AuthenticatedUser';
import { useLocalStorage } from './LocalStorage';

const LOCAL_STORAGE_KEY = 'graend.crew.user';
const ADMIN_GROUP_EMAIL = 'hellon-hive-admin@hellon.com';

interface AuthContextType {
  authenticated: boolean;
  inProgress: boolean;
  user: AutheticatedUser;
  signIn: () => void;
  signOut: () => void;
}
const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProps {
  children: JSX.Element | JSX.Element[];
}

const getUser = async () => {
  return Auth.currentAuthenticatedUser()
    .then((userData) => {
      return userData.signInUserSession.idToken.payload;
    })
    .catch(() => console.log('Not signed in'));
};

export const AuthProvider = ({ children }: AuthProps) => {
  const [user, setUser] = useLocalStorage(LOCAL_STORAGE_KEY, null);
  const [authenticated, setAuthenticated] = useState<boolean>(user != null);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', async ({ payload: { event, data } }) => {
      console.log('Hub::event', event, data);
      switch (event) {
        case 'signIn':
          const usr = await getUser();
          setUser(usr);

          setAuthenticated(true);
          setInProgress(false);
          break;
        case 'signOut':
        case 'oAuthSignOut':
          setAuthenticated(false);
          setUser(null);
          break;
      }
    });
    return () => {
      unsubscribe();
    };
  });

  const signIn = async () => {
    setInProgress(true);
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const signOut = async () => {
    await Auth.signOut();
  };

  const isAdmin = () => {
    return user['cognito:groups'].includes(ADMIN_GROUP_EMAIL);
  };

  if (user) {
    user['isAdmin'] = isAdmin;
  }

  if (authenticated) {
    console.log('AuthProvider::authenticated as', user.email, 'isAdmin', user.isAdmin());
  }

  const value = useMemo(
    () => ({
      authenticated,
      inProgress,
      user,
      signIn,
      signOut
    }),
    [authenticated, user, inProgress]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
