import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Projects, useUpdateProjectMutation } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { formatDate } from '../../utils/formatters';
import { SideDrawerSubmitButtons } from '../Drawers/SideDrawerSubmitButtons';
import { DateRangeInput } from '../Inputs/DateRangeInput';
import { TextInput } from '../Inputs/TextInput';

type ProjectEditFormProps = {
  project: Projects;
  onComplete: () => void;
};
export const ProjectEditForm = ({ project, onComplete }: ProjectEditFormProps) => {
  const { dispatch } = useContext(SystemUiContext);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Projects>({
    shouldUnregister: true
  });

  const [updateProject] = useUpdateProjectMutation({
    onError: (err: any) => {
      console.log(err);
      setInProgress(false);
    },
    onCompleted: (_resp: any) => {
      onComplete();
      setInProgress(false);
      dispatch?.({
        type: SystemUiActions.SHOW_DRAWER,
        payload: {
          open: false,
          content: null
        }
      });
    }
  });

  const onFormSubmit = (data: any) => {
    const values = {
      name: data.name,
      po: data.po,
      start_date: data.period?.startDate,
      end_date: data.period?.endDate
    };

    const filtered = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v != null && v !== '')
    );

    if (Object.keys(filtered).length > 0) {
      setInProgress(true);
      updateProject({
        variables: {
          id: project.id,
          data: filtered
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className='p-6 space-y-6'>
      <TextInput<any>
        name='name'
        register={register}
        label={'Project name'}
        type={'string'}
        rules={{ required: false }}
        errors={errors}
        placeholder={project?.name || ''}
      />
      <DateRangeInput<any>
        name='period'
        register={register}
        label={'Project duration'}
        errors={errors}
        control={control}
        rules={{ required: false }}
        placeHolder={`${formatDate(project.start_date)} -> ${formatDate(project.end_date)}`}
      />
      <TextInput<any>
        name='po'
        register={register}
        label={'PO'}
        type={'string'}
        rules={{ required: false }}
        errors={errors}
        placeholder={project?.po || ''}
      />
      <SideDrawerSubmitButtons inProgress={inProgress} />
    </form>
  );
};
