import { Assignments, Time_Entries } from '../generated/graphql';
import { customerLogoUrl } from './ProjectTypes';

export type TimeEntryFilter = (entry: Time_Entries) => boolean;

export const BillableTimeEntryFilter = (entry: Time_Entries): boolean =>
  entry.assignment?.project?.type === 'billable';

export const filterTimeEntries = (entries: Time_Entries[], filter: TimeEntryFilter) => {
  return entries.reduce((prev, current) => {
    const include = filter(current);
    if (include) {
      prev.push(current);
    }
    return prev;
  }, [] as Time_Entries[]);
};

export const totalHours = (entries: Time_Entries[]): number => {
  return entries.reduce((prev: number, value: Time_Entries) => {
    return prev + value.hours;
  }, 0);
};

export const totalBilling = (entries: Time_Entries[]): number => {
  return entries.reduce((prev: number, value: Time_Entries) => {
    return prev + value.hours * value.assignment?.external_price || 0;
  }, 0);
};

export const sortByTimeEntriesByDate = (entries: Time_Entries[]): Time_Entries[] => {
  return [...entries].sort((a, b) => {
    return new Date(a.day).getTime() - new Date(b.day).getTime();
  });
};

export type TimeEntrySummary = {
  id: string;
  hours?: number;
  project_name?: string;
  assignment_name?: string;
  external_price: number;
  internal_price: number;
  logo?: string;
  assignment: Assignments;
};

export type TimeEntrySummaryList = {
  [key: string]: TimeEntrySummary;
};

export const groupByProject = (entries: Time_Entries[]): TimeEntrySummary[] => {
  return Object.values(
    entries.reduce((prev: TimeEntrySummaryList, value: Time_Entries) => {
      let p = prev[value.assignment?.project?.name || ''];
      if (!p) {
        p = {
          id: value.id,
          hours: 0,
          project_name: value.assignment?.project?.name || '',
          external_price: value.assignment?.external_price,
          internal_price: value.assignment?.internal_price,
          assignment_name: value.assignment?.name || '',
          logo: customerLogoUrl(value.assignment?.project?.customer!),
          assignment: value.assignment!
        };
        prev[value.assignment?.project?.name || ''] = p;
      }
      p.hours += value.hours;

      return prev;
    }, {})
  );
};
