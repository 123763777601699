import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import './index.css';
import App from './App';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';

const shouldInitSentry = (process.env.REACT_APP_ENVIRONMENT || 'local') !== 'local';
if (shouldInitSentry) {
  const tracesSampleRate = parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '0');
  const replaysSampleRate = parseFloat(
    process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || '0'
  );
  const replaysErrorRate = parseFloat(
    process.env.REACT_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || '0'
  );

  function strToBool(str: string) {
    return str.toLowerCase() === 'true';
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[500, 599]]
      }),
      new Sentry.Replay({
        maskAllText: strToBool(process.env.REACT_APP_SENTRY_REPLAY_MASK_ALL_TEXT || 'false'),
        blockAllMedia: strToBool(process.env.REACT_APP_SENTRY_REPLAY_BLOCK_ALL_MEDIA || 'false')
      })
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: tracesSampleRate,
    replaysSessionSampleRate: replaysSampleRate,
    replaysOnErrorSampleRate: replaysErrorRate,
    sendDefaultPii: true
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
